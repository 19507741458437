import { DateRange } from 'bold-ui'
import { isLotacaoOrEstagio } from 'components/auth/useSessionUtils'
import { GridArea } from 'components/pivot-table/classes/GridArea'
import { PivotTableProps } from 'components/pivot-table/PivotTable'
import { SessaoDataFragment } from 'graphql/types.generated'
import moment from 'moment'
import { dateAsYyyyMmDdHhMm } from 'util/date/formatDate'
export class CsvBuilder {
  private csvTable: string[][] = [[]]

  private CSV_SEPARATOR = ';'

  public append = (gridArea: GridArea, data: string | number, rowTitle?: boolean, repeatTimesBefore?: number) => {
    const repeatTimes = gridArea.columnEnd - gridArea.columnStart

    let value = !rowTitle
      ? `${data}${this.CSV_SEPARATOR}`.repeat(repeatTimes)
      : data + this.CSV_SEPARATOR.repeat(repeatTimes)

    if (repeatTimesBefore) {
      value = this.CSV_SEPARATOR.repeat(repeatTimesBefore) + value
    }

    for (let i = gridArea.rowStart; i < (!rowTitle ? gridArea.rowStart + 1 : gridArea.rowEnd); i++) {
      if (this.csvTable[i]) {
        this.csvTable[i][gridArea.columnStart] = value
      } else {
        this.csvTable[i] = []
        if (rowTitle) {
          this.csvTable[i][gridArea.columnStart] = this.CSV_SEPARATOR.repeat(gridArea.columnStart - 1) + value
        } else {
          this.csvTable[i][gridArea.columnStart] = value
        }
      }
    }
  }

  public build<T>(
    title: string,
    filterDataKeyValues: Map<keyof T, Set<string>>,
    keysMapping: PivotTableProps<T>['keyMapping'],
    data: SessaoDataFragment,
    dateRangeFilter: DateRange,
    aggregatorLabel: string
  ) {
    let csvContent = 'e-SUS - APS\n'
    csvContent += 'MINISTÉRIO DA SAÚDE\n'
    if (data.acesso.municipio) {
      csvContent += 'ESTADO DE ' + data.acesso.municipio.uf.nome.toUpperCase() + '\n'
      csvContent += 'MUNICÍPIO DE ' + data.acesso.municipio.nome.toUpperCase() + '\n'
    } else {
      for (let acesso of data.profissional.acessos) {
        if (acesso.__typename === 'GestorEstadual') {
          csvContent += 'ESTADO DE ' + acesso.uf.nome.toUpperCase() + '\n'
          break
        }
      }
    }
    csvContent += isLotacaoOrEstagio(data.acesso)
      ? 'UNIDADE DE SAÚDE ' + data.acesso.unidadeSaude.nome.toUpperCase() + '\n'
      : ''
    csvContent += '\n'
    csvContent += title + '\n'
    csvContent += '\n'
    csvContent += 'FILTROS\n'

    csvContent += 'Período:' + this.CSV_SEPARATOR
    csvContent +=
      this.dateToDDMMYYYY(dateRangeFilter.startDate) +
      ' a ' +
      this.dateToDDMMYYYY(dateRangeFilter.endDate) +
      this.CSV_SEPARATOR
    csvContent += '\n'

    filterDataKeyValues.forEach((value, key) => {
      const values = keysMapping.get(key)
      csvContent += values.keyName + ':' + this.CSV_SEPARATOR
      value.forEach((element) => {
        csvContent += this.parseAsCsvString(values.formatter?.(element) ?? element) + this.CSV_SEPARATOR
      })
      csvContent += '\n'
    })
    csvContent += '\n'

    csvContent += 'Forma de apresentação:' + this.CSV_SEPARATOR
    csvContent += aggregatorLabel
    csvContent += '\n'

    const printTime = new Date()
    csvContent +=
      'Gerado em ' +
      this.dateToDDMMYYYY(printTime) +
      ' às ' +
      moment(printTime).format('HH:mm') +
      ' por ' +
      data.profissional.nome.toUpperCase() +
      '\n'

    this.csvTable.forEach((line) => {
      line.forEach((column) => {
        csvContent += column
      })
      csvContent += '\n'
    })

    const BOM = '%EF%BB%BF'
    const href = 'data:text/csv;charset=utf-8,' + BOM + encodeURI(csvContent)

    let link = document.createElement('a')
    link.setAttribute('href', href)
    link.setAttribute(
      'download',
      title.replace(' ', '-').replace(' de ', '-').toLowerCase() + `-${dateAsYyyyMmDdHhMm(printTime)}.csv`
    )
    link.click()
  }

  private dateToDDMMYYYY = (date: Date): string => {
    return moment(date).format('DD/MM/YYYY')
  }

  private parseAsCsvString = (text: string): string => {
    return `"${text}"`
  }
}
