import { VFlow } from 'bold-ui'
import { ErrorField } from 'components/form/final-form/ErrorField/ErrorField'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoExecutanteFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { AgendarConsultaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/AgendarConsultaCuidadoCompartilhadoField'
import { DevolutivaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/DevolutivaCuidadoCompartilhadoField'
import { SugestaoAgendamentoGrupoCuidadoCompartilhadoField } from '../components/discussao-caso-fields/SugestaoAgendamentoGrupoCuidadoCompartilhadoField'
import { TrocaExecutanteCuidadoCompartilhadoField } from '../components/discussao-caso-fields/TrocaExecutanteCuidadoCompartilhadoField'

interface DiscussaoCasoExecutanteFieldsProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
}

export const DiscussaoCasoExecutanteFields = (props: DiscussaoCasoExecutanteFieldsProps) => {
  const { path, cuidadoCompartilhado, videochamadaUuidState } = props
  const { CUIDADO_COMPARTILHADO_ENABLED } = useFlags()

  return (
    <VFlow vSpacing={0.5}>
      <DevolutivaCuidadoCompartilhadoField path={path} />

      {CUIDADO_COMPARTILHADO_ENABLED && (
        <TrocaExecutanteCuidadoCompartilhadoField
          path={path}
          lotacaoSolicitante={cuidadoCompartilhado.lotacaoSolicitante}
          profissionalExecutanteId={cuidadoCompartilhado.lotacaoExecutanteAtual?.profissional?.id}
        />
      )}

      {CUIDADO_COMPARTILHADO_ENABLED && (
        <AgendarConsultaCuidadoCompartilhadoField
          path={path}
          cuidadoCompartilhado={cuidadoCompartilhado}
          videochamadaUuidState={videochamadaUuidState}
        />
      )}

      {CUIDADO_COMPARTILHADO_ENABLED && <SugestaoAgendamentoGrupoCuidadoCompartilhadoField path={path} />}

      <ErrorField name={path} ignoreObjectError />
    </VFlow>
  )
}
