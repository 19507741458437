import { HFlow, Text } from 'bold-ui'
import { EquipeSelectModel, SelectField } from 'components/form'
import { EquipeSelectFieldDocument } from 'graphql/hooks.generated'
import { EquipeSelectFieldQuery, EquipeSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface EquipeSelectFieldProps extends AsyncSelectFieldProps<EquipeSelectModel> {
  unidadeSaudeId: ID
  mostrarInativas?: boolean
  showSemEquipeOption?: boolean
}

export const SEM_EQUIPE_ITEM = {
  id: '0',
  nome: 'Sem Equipe',
  ine: '-',
  ativo: true,
  tipoEquipe: {
    id: '',
    nome: '',
    sigla: '',
  },
}

const itemToString = (option: EquipeSelectModel) => option && option.nome

export function EquipeSelectField(props: EquipeSelectFieldProps) {
  const { unidadeSaudeId, mostrarInativas, showSemEquipeOption, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    EquipeSelectModel,
    EquipeSelectFieldQuery,
    EquipeSelectFieldQueryVariables
  >({
    query: EquipeSelectFieldDocument,
    extractItems: (data) => [
      ...(showSemEquipeOption ? [SEM_EQUIPE_ITEM] : []),
      ...(data?.unidadeSaude?.equipes?.content ?? []),
    ],
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        mostrarInativas,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
      unidadeSaudeId,
    }),
  })

  const renderItem = (item: EquipeSelectModel) => (item.id === '0' ? renderItemSemEquipe() : renderItemComEquipe(item))

  const renderItemSemEquipe = () => <Text fontWeight='bold'>Sem Equipe</Text>

  const renderItemComEquipe = (item: EquipeSelectModel) => (
    <>
      <Text fontWeight='bold'>
        {item.nome.capitalize()} - {item.tipoEquipe.sigla} {!item.ativo && mostrarInativas ? ' (Inativa)' : ''}
      </Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>INE:</Text>
        {item.ine}
      </HFlow>
    </>
  )

  return (
    <SelectField<EquipeSelectModel> itemToString={itemToString} renderItem={renderItem} {...selectProps} {...rest} />
  )
}
