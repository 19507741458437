import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { CBOS_ACESSO_CC, NIVEL_SUPERIOR } from 'types/NivelCbo'

import { CuidadoCompartilhadoRootView } from './cuidado-compartilhado/CuidadoCompartilhadoRootView'
import { ListaCuidadoCompartilhadoExecutanteView } from './lista-cuidados-compartilhados/ListaCuidadoCompartilhadoExecutanteView'
import { ListaCuidadoCompartilhadoRootView } from './lista-cuidados-compartilhados/ListaCuidadoCompartilhadoRootView'
import { ListaCuidadoCompartilhadoSolicitanteView } from './lista-cuidados-compartilhados/ListaCuidadoCompartilhadoSolicitanteView'
import { ResponsavelCuidadoCompartilhado } from './model-cuidadoCompartilhado'
import { VisualizarCuidadoCompartilhadoRootView } from './visualizar-cuidado-compartilhado/VisualizarCuidadoCompartilhadoRootView'

export const LCC_TABS_ROUTE: Record<ResponsavelCuidadoCompartilhado, string> = {
  EXECUTANTE: 'compartilhamentos',
  SOLICITANTE: 'solicitacoes',
}

export const CuidadosCompartilhadosRootView = () => {
  const match = useRouteMatch()

  return (
    <>
      <Breadcrumb title='Cuidados compartilhados' />
      <PecSwitch>
        <PrivateRoute
          path={`${match.url}/${LCC_TABS_ROUTE['EXECUTANTE']}/:id`}
          component={CuidadoCompartilhadoRootView}
          cbos={NIVEL_SUPERIOR}
        />
        <PrivateRoute
          path={`${match.url}/${LCC_TABS_ROUTE['SOLICITANTE']}/:id`}
          component={CuidadoCompartilhadoRootView}
          cbos={NIVEL_SUPERIOR}
        />
        <PrivateRoute
          path={`${match.url}/${LCC_TABS_ROUTE['EXECUTANTE']}`}
          component={ListaCuidadoCompartilhadoExecutanteView}
          cbos={NIVEL_SUPERIOR}
        />
        <PrivateRoute
          path={`${match.url}/${LCC_TABS_ROUTE['SOLICITANTE']}`}
          component={ListaCuidadoCompartilhadoSolicitanteView}
          cbos={NIVEL_SUPERIOR}
        />
        <PrivateRoute
          path={`${match.url}/visualizar/:id`}
          component={VisualizarCuidadoCompartilhadoRootView}
          cbos={NIVEL_SUPERIOR}
        />

        <PrivateRoute exact path={match.url} component={ListaCuidadoCompartilhadoRootView} cbos={CBOS_ACESSO_CC} />
      </PecSwitch>
    </>
  )
}
