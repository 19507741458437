import { focusBoxShadow, hexToRGB, Text, Theme, useStyles } from 'bold-ui'
import { NormalizedHighlighter } from 'components/highlight/NormalizedHighlighter'
import { useRocksdbEnabledQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { CSSProperties } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { REL_GERENCIAL_URL_ATENDIMENTOS, REL_GERENCIAL_URL_VACINACAO } from './SideMenuItems'

export interface SideMenuSubLinkProps extends NavLinkProps {
  title: string
  highlight?: string
  logEventParameter?: string
  onNavigate?(): void
}

export function SideMenuSubLink(props: SideMenuSubLinkProps) {
  const { classes } = useStyles(createStyles, true)
  const { title, highlight, onNavigate, logEventParameter, to, ...rest } = props
  const { analytics } = useFirebase()

  const handleClick = () => {
    logEventParameter
      ? analytics.logEvent('side_menu_click', { modulo_selecionado: logEventParameter })
      : analytics.logEvent('side_menu_click')
    onNavigate && onNavigate()
  }

  const { data } = useRocksdbEnabledQuery()

  if (!data.info?.rocksdbEnabled && (to === REL_GERENCIAL_URL_VACINACAO || to === REL_GERENCIAL_URL_ATENDIMENTOS)) {
    return null
  }

  return (
    <li className={classes.subitem}>
      <NavLink className={classes.sublink} onClick={handleClick} to={to} {...rest}>
        <Text color='inherit'>
          <NormalizedHighlighter searchWords={highlight} textToHighlight={title} />
        </Text>
      </NavLink>
    </li>
  )
}

export const createStyles = (theme: Theme) => ({
  subitem: {
    background: theme.pallete.gray.c90,
  },
  sublink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.pallete.text.main,
    textDecoration: 'none',
    outline: 'none',
    padding: '0.5rem 1.5rem 0.5rem 5rem',
    fontWeight: 'normal',

    '&:hover': {
      background: hexToRGB(theme.pallete.gray.c20, 0.1),
    },

    '&:focus': {
      borderRadius: 4,
      boxShadow: focusBoxShadow(theme, 'primary', 'inset'),
    },

    '&.active': {
      color: theme.pallete.primary.main,
      fontWeight: 'bold',
    },
  } as CSSProperties,
})
