import ApolloClient from 'apollo-client'
import { VinculacaoServicoDocument } from 'graphql/hooks.generated'
import { UnidadeSaude } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { VincularEstabelecimentoReferenciaFormModel } from './model-vincularEstabelecimentoReferencia'

export const vincularEstabelecimentoCalculator = (
  meta: MetaRoot<VincularEstabelecimentoReferenciaFormModel>,
  isEdit: boolean,
  apollo: ApolloClient<object>
) => [
  {
    field: meta.referencia.absolutePath(),
    updates: {
      [meta.vinculacoes.absolutePath()]: (
        estabelecimentoReferencia: UnidadeSaude,
        allValues: VincularEstabelecimentoReferenciaFormModel
      ) => {
        if (isEdit) {
          return allValues.vinculacoes
        } else {
          const referenciaId = estabelecimentoReferencia?.id
          if (!isUndefinedOrNull(referenciaId)) {
            return apollo
              .query({
                query: VinculacaoServicoDocument,
                variables: { referenciaId },
                fetchPolicy: 'network-only',
              })
              .then((res) => res.data.vinculacaoServico?.vinculacoes ?? [])
          } else {
            return []
          }
        }
      },
    },
  },
]
