import { Alert, DateRange, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useFirebase } from 'hooks/firebase/useFirebase'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import { Board } from './components/filter/Board'
import { PivotTableRender } from './components/table/PivotTableRender'
import { noKeyErrorText } from './noKeyErrorText'
import { TreeMeta } from './TreeBuilder'
import { Aggregator, aggregators, Dictionary } from './types'

export type PivotTableProps<T extends any> = {
  title: string
  keyMapping: Map<
    keyof T,
    { keyName: string; formatter?: (value: string) => string; ordenator?: (a: string, b: string) => number }
  >
  meta: TreeMeta<T>
  maxLeafValue: number
  defaultTree: Dictionary<T>
  complementaryTree: Dictionary<T>
  isBuilding: boolean
  build<K extends keyof T>(
    rowKeys: Array<K>,
    columnKeys: Array<K>,
    aggregator: Aggregator,
    aggregatorKey: keyof T,
    filterKeys?: Map<K, Set<string>>
  )
  demolish: () => void
  dateRangeFilter: DateRange
}
interface PivotTableContextType {
  total: number
  suffix?: string
}

export const PivotTableContext = React.createContext<PivotTableContextType>(undefined)

export function PivotTable<T>(props: PivotTableProps<T>) {
  const {
    keyMapping,
    title,
    meta: { keyValues, numberKeys },
    maxLeafValue,
    defaultTree,
    complementaryTree,
    isBuilding,
    build,
    demolish,
    dateRangeFilter,
  } = props

  const pivotTableRef = useRef(null)

  const [filterDataKeyValues, setFilterDataKeyValue] = useState<Map<keyof T, Set<string>>>()

  const [rowKeys, setRowKeys] = useState<Array<keyof T>>([])

  const [columnKeys, setColumnKeys] = useState<Array<keyof T>>([])

  const [aggregator, setAggregator] = useState<Aggregator>(aggregators[0])

  const [aggregatorKey, setAggregatorKey] = useState<keyof T>()

  const { analytics } = useFirebase()

  const alert = useAlert()

  const handleAggregatorKey = (key: keyof T) => setAggregatorKey(key)
  const handleAggregator = (aggregator: Aggregator) => {
    setAggregator(() => aggregator)
  }

  useEffect(() => {
    if (rowKeys?.length > 0 || columnKeys?.length > 0) {
      const init = performance.now()

      build(rowKeys, columnKeys, aggregator, aggregatorKey, filterDataKeyValues)

      const final = performance.now()

      const format = 'DD/MM/YYYY'

      analytics.logEvent('relatorios_bi_gerar_tabela', {
        relatorio: title,
        periodo: `${moment(dateRangeFilter.startDate).format(format)} até ${moment(dateRangeFilter.endDate).format(
          format
        )}`,
        tempo: `${(final - init).toFixed(2)} ms`,
        linhas: rowKeys.map((r) => keyMapping.get(r).keyName),
        colunas: columnKeys.map((c) => keyMapping.get(c).keyName),
      })
    }
  }, [
    rowKeys,
    columnKeys,
    filterDataKeyValues,
    aggregator,
    aggregatorKey,
    build,
    analytics,
    title,
    keyMapping,
    dateRangeFilter,
  ])

  useEffect(() => {
    if (defaultTree) {
      if (defaultTree.nodeValue === undefined) {
        alert('warning', 'Os filtros selecionados ou a combinação de linhas e colunas não possuem dados')
      } else if ((rowKeys?.length > 0 || columnKeys?.length) && !isBuilding) {
        window.scrollTo({ behavior: 'smooth', top: pivotTableRef.current?.offsetTop })
      }
    }
  }, [alert, columnKeys, defaultTree, isBuilding, rowKeys])

  const handleSubmit = (values: [Array<keyof T>, Array<keyof T>], newFilters: Map<keyof T, Set<string>>) => {
    const [newRowKeys, newColumnKeys] = values

    if (newRowKeys.length === 0 && newColumnKeys.length === 0) {
      alert('warning', 'Arraste pelo menos um dos campos disponíveis para os quadros de linhas ou colunas.')
      return
    }

    const errorList = []

    for (let rowKey of newRowKeys) {
      const isFilterEmpty = !newFilters?.get(rowKey)?.size
      isFilterEmpty && errorList.push(keyMapping.get(rowKey).keyName)
    }

    for (let columnKey of newColumnKeys) {
      const isFilterEmpty = !newFilters?.get(columnKey)?.size
      isFilterEmpty && errorList.push(keyMapping.get(columnKey).keyName)
    }

    if (errorList.length) {
      alert(
        'warning',
        'Nenhuma opção selecionada para o(s) campo(s) ' +
          noKeyErrorText(errorList) +
          'presente(s) no(s) quadros de linhas e colunas.'
      )
      return
    }

    setFilterDataKeyValue(newFilters)
    setRowKeys(newRowKeys)
    setColumnKeys(newColumnKeys)

    if (newRowKeys !== rowKeys || newColumnKeys !== columnKeys || newFilters !== filterDataKeyValues) {
      demolish()
    }
  }

  const handleReset = () => {
    demolish()
    setRowKeys([])
    setColumnKeys([])
  }

  if (keyValues) {
    return (
      <VFlow>
        <Alert inline type='info'>
          Para gerar uma tabela, arraste os <b>Campos Disponíveis</b> desejados para os quadros de <b>Linhas</b> e{' '}
          <b>Colunas</b>, selecione a forma de apresentação e clique em <b>Gerar tabela</b>.
        </Alert>
        <Board<T>
          keys={keyValues}
          keyMapping={keyMapping}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          numberKeys={numberKeys}
          isBuilding={isBuilding}
          handleAggregatorChange={handleAggregator}
          handleAggregatorKeyChange={handleAggregatorKey}
          aggregator={aggregator}
          aggregatorKey={aggregatorKey}
        />
        {defaultTree &&
          defaultTree.nodeValue !== undefined &&
          !isBuilding &&
          (rowKeys?.length > 0 || columnKeys?.length) && (
            <PivotTableContext.Provider value={{ total: maxLeafValue, suffix: aggregator?.suffix }}>
              <div ref={pivotTableRef}>
                <PivotTableRender
                  rowKeys={rowKeys}
                  columnKeys={columnKeys}
                  keysMapping={keyMapping}
                  defaultTree={defaultTree}
                  complementaryTree={complementaryTree}
                  title={title}
                  filterDataKeyValues={filterDataKeyValues}
                  dateRangeFilter={dateRangeFilter}
                  aggregatorLabel={aggregator.label}
                />
              </div>
            </PivotTableContext.Provider>
          )}
      </VFlow>
    )
  } else {
    return (
      <div>
        <b>Loading...</b>
      </div>
    )
  }
}
