import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { useSalvarVinculacaoServicoMutation, useVinculacaoServicoQuery } from 'graphql/hooks.generated'
import { UnidadeSaude } from 'graphql/types.generated'
import React from 'react'
import { Redirect, useHistory, useParams, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { SERVICOS_REFERENCIA_PATH } from '../../ServicosReferenciaView'
import { VincularEstabelecimentoReferenciaFormModel } from './model-vincularEstabelecimentoReferencia'
import { VincularEstabelecimentoReferenciaForm } from './VincularEstabelecimentoReferenciaForm'

interface UrlParams {
  estabelecimentoReferenciaId: string
}

export function VincularEstabelecimentoReferenciaView() {
  const match = useRouteMatch()
  const history = useHistory()
  const params = useParams<UrlParams>()
  const alert = useAlert()
  const [salvarVinculacaoServicoMutation] = useSalvarVinculacaoServicoMutation()

  const onSubmit = (values: VincularEstabelecimentoReferenciaFormModel) =>
    salvarVinculacaoServicoMutation({
      variables: {
        input: {
          referenciaId: values.referencia.id,
          vinculacoesId: values.vinculacoes.map((receptor) => receptor.id),
        },
      },
    })

  const servicoReferenciaTabPath = getServicoReferenciaTabPath(match.url)

  const onSubmitSucceeded = () => {
    history.push(servicoReferenciaTabPath)
    alert('success', 'Vínculo realizado com sucesso')
  }

  const onCancel = () => {
    history.push(servicoReferenciaTabPath)
  }

  const isEdit = !isUndefinedOrNull(params.estabelecimentoReferenciaId)

  const {
    data: { vinculacaoServico },
    loading,
  } = useVinculacaoServicoQuery({
    variables: { referenciaId: params.estabelecimentoReferenciaId },
    skip: !isEdit,
  })

  const initialValues: VincularEstabelecimentoReferenciaFormModel = isEdit
    ? {
        referencia: vinculacaoServico?.referencia as UnidadeSaude,
        vinculacoes: vinculacaoServico?.vinculacoes as UnidadeSaude[],
      }
    : {
        referencia: null,
        vinculacoes: [],
      }

  return isEdit && !loading && isUndefinedOrNull(vinculacaoServico) ? (
    <Redirect to='/404' />
  ) : (
    <>
      <Breadcrumb title='Serviços de referência' to={servicoReferenciaTabPath} />
      <Breadcrumb title='Vinculação de referência' />
      <PageHeaderSection title='Vinculação de referência' />
      <PageContent type='filled'>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <VincularEstabelecimentoReferenciaForm
            initialValues={initialValues}
            isEdit={isEdit}
            onSubmit={onSubmit}
            onSubmitSucceeded={onSubmitSucceeded}
            onCancel={onCancel}
          />
        )}
      </PageContent>
    </>
  )
}

const getServicoReferenciaTabPath = (url: string) =>
  `${url.split(SERVICOS_REFERENCIA_PATH)[0]}${SERVICOS_REFERENCIA_PATH}`
