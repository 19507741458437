import { isAfter } from 'date-fns'
import { TipoFrequencia, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import {
  afterEqualTo,
  beforeEqualTo,
  composeRules,
  createValidator,
  ErrorObject,
  maxLength,
  minLength,
  minRange,
  required,
} from 'util/validation'
import { isEmpty } from 'util/validation/Util'

import { MedicamentoFormModel } from '../model'
import {
  calculateDataFimTratamento,
  duracaoFrequencia,
  isCampoInvalido,
  isDoseUnicaComCamposProibidos,
} from './utils-prescricao'

export const prescricaoFormValidator = (dataAtendimento: Date, isRegistroTardio: Boolean = false) =>
  createValidator<MedicamentoFormModel>(
    {
      viaAdministracao: [required],
      principioAtivoText: [maxLength(200)],
      qtDose: [required, minLength(1), maxLength(5), minRange(0.01)],
      unidadeMedidaDose: [required],
      concentracaoText: [maxLength(100)],
      dataInicioTratamento: [required],
      quantidade: [required, minLength(1), maxLength(3), minRange(1)],
      duracao: [minRange(1)],
    },
    (values: MedicamentoFormModel, errors: ErrorObject<MedicamentoFormModel>) => {
      if (!values) {
        return errors
      }

      if (values.qtDose?.includes('/')) {
        if (values.qtDose.endsWith('/')) {
          errors.qtDose = 'Insira um valor após a barra.'
        } else if (values.qtDose.endsWith('0')) {
          errors.qtDose = 'Não é possível realizar uma divisão por zero.'
        }
      } else if (values.qtDose?.endsWith(',')) {
        errors.qtDose = 'Insira um valor após a vírgula.'
      }

      if (values.dataInicioTratamento) {
        const dataInicioTratamento = new Date(values.dataInicioTratamento)
        const dataAtual = new Date()

        if (dataInicioTratamento > dataAtual && isRegistroTardio) {
          errors.dataInicioTratamento = beforeEqualTo(values.dataInicioTratamento, dataAtual.toString(), 'data atual')
        }

        if (dataInicioTratamento < dataAtendimento) {
          errors.dataInicioTratamento = afterEqualTo(
            values.dataInicioTratamento,
            dateAsYyyyMmDd(dataAtendimento),
            'data do atendimento'
          )
        }
      }

      if (values.registroManual) {
        errors.principioAtivoText = composeRules([required, maxLength(200)])(values.principioAtivoText)
        errors.formaFarmaceutica = required(values.formaFarmaceutica)
        errors.tipoReceita = required(values.tipoReceita)
        errors.unidadeFornecimento = composeRules([required, minLength(2), maxLength(100)])(values.unidadeFornecimento)
      } else {
        !values.principioAtivoCombo && (errors.principioAtivoCombo = required(values.principioAtivoCombo))
      }

      if (UnidadeMedidaTempoEnum.INDETERMINADO !== values.escalaDuracao && !values.duracao) {
        errors.duracao = required(values.duracao)
      }

      if (values.doseUnica) {
        errors.intervaloDose = isCampoInvalido(!isEmpty(values.intervaloDose))(values.intervaloDose)
        errors.frequenciaDose = isCampoInvalido(!isEmpty(values.frequenciaDose))(values.frequenciaDose)
        errors.unidadeMedidaTempoFrequenciaTurno = isCampoInvalido(!isEmpty(values.unidadeMedidaTempoFrequenciaTurno))(
          values.unidadeMedidaTempoFrequenciaTurno
        )
        errors.quantidadePeriodoFrequenciaTurno = isCampoInvalido(!isEmpty(values.quantidadePeriodoFrequenciaTurno))(
          values.quantidadePeriodoFrequenciaTurno
        )
        errors.frequenciaDuracao = isDoseUnicaComCamposProibidos(
          !isEmpty(values.quantidadePeriodoFrequenciaTurno),
          'Receita com dose única não pode ter uma quantidade de frequência'
        )
      } else {
        if (TipoFrequencia.INTERVALO === values.tipoFrequencia) {
          errors.intervaloDose = minRange(1)(values.intervaloDose)

          const intervaloIsAfterDuracao = isAfter(
            calculateDataFimTratamento(
              values.intervaloDose,
              values.dataInicioTratamento,
              values.unidadeMedidaTempoFrequenciaTurno
            ),
            calculateDataFimTratamento(values.duracao, values.dataInicioTratamento, values.escalaDuracao)
          )
          if (intervaloIsAfterDuracao) {
            if (values.duracao) {
              errors.intervaloDose = isCampoInvalido(intervaloIsAfterDuracao)(values.intervaloDose)
              errors.duracao = isCampoInvalido(intervaloIsAfterDuracao)(values.duracao)
              errors.frequenciaDuracao = duracaoFrequencia(
                values.intervaloDose,
                values.dataInicioTratamento,
                values.unidadeMedidaTempoFrequenciaTurno,
                values.escalaDuracao
              )(values.duracao)
            }
          } else if (!values.intervaloDose) {
            errors.intervaloDose = required(values.intervaloDose)
          }
        } else {
          if (values.tipoFrequencia === TipoFrequencia.FREQUENCIA) {
            errors.frequenciaDose = composeRules([required, minRange(1)])(values.frequenciaDose)
          } else if (TipoFrequencia.TURNO === values.tipoFrequencia) {
            errors.turno = required(values.turno)
          }
          errors.quantidadePeriodoFrequenciaTurno = minRange(1)(values.quantidadePeriodoFrequenciaTurno)
          const frequenciaIsAfterDuracao = isAfter(
            calculateDataFimTratamento(
              values.quantidadePeriodoFrequenciaTurno,
              values.dataInicioTratamento,
              values.unidadeMedidaTempoFrequenciaTurno
            ),
            calculateDataFimTratamento(values.duracao, values.dataInicioTratamento, values.escalaDuracao)
          )
          if (frequenciaIsAfterDuracao) {
            if (values.quantidadePeriodoFrequenciaTurno && values.duracao) {
              errors.quantidadePeriodoFrequenciaTurno = isCampoInvalido(frequenciaIsAfterDuracao)(
                values.quantidadePeriodoFrequenciaTurno
              )
              errors.duracao = isCampoInvalido(frequenciaIsAfterDuracao)(values.duracao)
              errors.frequenciaDuracao = duracaoFrequencia(
                values.quantidadePeriodoFrequenciaTurno,
                values.dataInicioTratamento,
                values.unidadeMedidaTempoFrequenciaTurno,
                values.escalaDuracao
              )(values.duracao)
            }
          } else {
            errors.quantidadePeriodoFrequenciaTurno = composeRules([required, minRange(1)])(
              values.quantidadePeriodoFrequenciaTurno
            )
            errors.unidadeMedidaTempoFrequenciaTurno = required(values.unidadeMedidaTempoFrequenciaTurno)
          }
        }
      }
      return errors
    }
  )
