/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, DataTable, Grid, Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import { isProcedimentoSisab } from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { LoadingIndicator } from 'components/loading'
import { format } from 'date-fns'
import {
  useHistoricoAtendimentoIndividualDwQuery,
  useHistoricoConcatenadaProcedimentoQuery,
  useHistoricoConcatenadaZikaQuery,
} from 'graphql/hooks.generated'
import { DimensaoProcedimento } from 'graphql/types.generated'
import { partition } from 'lodash'
import { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { isEmpty } from 'util/validation/Util'
import {
  FichasConcatenadasHistorico,
  HistoricoPanelProps,
  HistoricoSectionStyles,
  historicoSubsectionStyles,
} from 'view/atendimentos/detail/historico/model/model-historico'
import {
  hasAllAttrsNull,
  mountInfosAdministrativasAtendimentoCompartilhado,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoDetailTable from '../../../../table/HistoricoDetailTable'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'
import { ResultadosDeExamesZikaContent } from '../../zika/ResultadosDeExamesZikaContent'
import GravidezAvaliacaoSection from './GravidezAvaliacaoSection'

interface HistoricoAtendimentoIndividualPanelProps extends HistoricoPanelProps {
  fichasConcatenadas: FichasConcatenadasHistorico
}

function HistoricoAtendimentoIndividualPanel(props: HistoricoAtendimentoIndividualPanelProps) {
  const { uuidRegistro, idadeOrigemAtendimento, cpfOuCnsCidadao, fichasConcatenadas } = props
  const {
    data: { historicoAtendimentoIndividualDw: historico },
    loading,
  } = useHistoricoAtendimentoIndividualDwQuery({
    variables: { input: { isOrigemPec: false, uuidRegistro, cpfOuCnsCidadao } },
  })

  const {
    data: { historicoZikaDetail: zikaConcatenada },
    loading: loadingZikaConcatenada,
  } = useHistoricoConcatenadaZikaQuery({
    variables: { input: { isOrigemPec: false, uuidRegistro: fichasConcatenadas?.uuidZika, cpfOuCnsCidadao } },
    skip: !fichasConcatenadas?.uuidZika,
  })

  const {
    data: { historicoProcedimentoDw: procedimentoConcatenada },
    loading: loadingProcedimentoConcatenada,
  } = useHistoricoConcatenadaProcedimentoQuery({
    variables: { input: { isOrigemPec: false, uuidRegistro: fichasConcatenadas?.uuidProcedimento, cpfOuCnsCidadao } },
    skip: !fichasConcatenadas?.uuidProcedimento,
  })

  if (loading || loadingZikaConcatenada || loadingProcedimentoConcatenada) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  const hasMedicoes =
    historico.nuPerimetroCefalico ||
    historico.nuPeso ||
    historico.nuAltura ||
    historico.nuImc ||
    historico.stVacinacaoEmDia ||
    historico.dum
  const hasMedicoesPreNatal = historico.stGravidezPlanejada || historico.nuIdadeGestacional
  const hasExamesAvaliados = !isEmpty(historico.examesAvaliados)
  const hasDadosPlano = !isEmpty(historico.examesSolicitados) || fichasConcatenadas?.uuidProcedimento
  const hasCiapCidAvaliados = !isEmpty(historico.cidsCiapsAvaliados)
  const hasNasfPolo = !isEmpty(historico.nasfPolo)

  const [procedimentosSisab, procedimentosSigtap] = partition(procedimentoConcatenada?.procedimentos, (procedimento) =>
    isProcedimentoSisab(procedimento.codigoProcedimento)
  )

  const hasProcedimentoSigtap = !isEmpty(procedimentosSigtap)
  const hasCondutas = !isEmpty(historico.condutaDesfecho)
  const hasEncaminhamentos = !isEmpty(historico.encaminhamentos)

  const renderExame = (item: DimensaoProcedimento) => (
    <Text>
      {item.descricaoProcedimento} - {item.codigoProcedimento}
    </Text>
  )

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentoCompartilhado(historico)}
      isAtendObservacao={historico.stFicouEmObservacao}
      tipoParticipacaoCidadao={historico.dimTipoParticipacaoCidadao?.descricao}
    >
      <HistoricoSection section='S'>
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      </HistoricoSection>
      <HistoricoSection section='O'>
        <VFlow>
          {!hasExamesAvaliados && !hasMedicoes && !historico.dimensaoAleitamento && (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
          {hasMedicoes && (
            <HistoricoDetailTable title='Medições' sectionStyle='O'>
              <VFlow>
                <Grid>
                  {historico.nuPerimetroCefalico && (
                    <Cell size={2}>
                      <InfoLabel title='Per. cefálico'>{`${historico.nuPerimetroCefalico} cm`}</InfoLabel>
                    </Cell>
                  )}
                  {historico.nuPeso && (
                    <Cell size={2}>
                      <InfoLabel title='Peso'>{`${historico.nuPeso} kg`}</InfoLabel>
                    </Cell>
                  )}
                  {historico.nuAltura && (
                    <Cell size={2}>
                      <InfoLabel title='Altura'>{`${historico.nuAltura} cm`}</InfoLabel>
                    </Cell>
                  )}
                  {historico.nuImc && (
                    <Cell size={2}>
                      <InfoLabel title='IMC'>{`${historico.nuImc.toFixed(2)} kg/m²`}</InfoLabel>
                    </Cell>
                  )}
                  {!isEmpty(historico.stVacinacaoEmDia) && (
                    <Cell size={2}>
                      <InfoLabel title='Vacinação em dia'>{historico.stVacinacaoEmDia ? 'Sim' : 'Não'}</InfoLabel>
                    </Cell>
                  )}
                  {historico.dum && (
                    <Cell size={2}>
                      <InfoLabel title='DUM'>{format(historico.dum, 'dd/MM/yyyy')}</InfoLabel>
                    </Cell>
                  )}
                </Grid>
              </VFlow>
            </HistoricoDetailTable>
          )}
          {hasMedicoesPreNatal && (
            <HistoricoDetailTable title='Medições pré-natal' sectionStyle='O'>
              <VFlow>
                <Grid>
                  {!isEmpty(historico.stGravidezPlanejada) && (
                    <Cell size={2}>
                      <InfoLabel title='Gravidez planejada'>{historico.stGravidezPlanejada ? 'Sim' : 'Não'}</InfoLabel>
                    </Cell>
                  )}
                  {historico.nuIdadeGestacional && (
                    <Cell size={2}>
                      <InfoLabel title='Idade gestacional'>
                        {'semana'.pluralizeAndConcatValue(historico.nuIdadeGestacional)}
                      </InfoLabel>
                    </Cell>
                  )}
                </Grid>
              </VFlow>
            </HistoricoDetailTable>
          )}
          {hasExamesAvaliados && (
            <VFlow vSpacing={0.5}>
              <Heading
                level={5}
                style={css`
                  color: ${HistoricoSectionStyles['O'].color};
                `}
              >
                Resultados de exames
              </Heading>
              <DataTable<DimensaoProcedimento>
                rows={historico.examesAvaliados}
                columns={[
                  {
                    name: 'nome',
                    header: 'Nome',
                    render: renderExame,
                  },
                ]}
              />
            </VFlow>
          )}
          {historico.dimensaoAleitamento && !hasAllAttrsNull(historico.dimensaoAleitamento) && (
            <VFlow vSpacing={0.5}>
              <Heading
                level={5}
                style={css`
                  color: ${HistoricoSectionStyles['O'].color};
                `}
              >
                Puericultura
              </Heading>
              <InfoLabel title='Tipo de aleitamento'>{historico.dimensaoAleitamento.descricao}</InfoLabel>
            </VFlow>
          )}
        </VFlow>
        {fichasConcatenadas?.uuidZika && (
          <VFlow vSpacing={0.5}>
            <Heading level={3} style={historicoSubsectionStyles.objetivo}>
              Síndrome por Zika/Microcefalia
            </Heading>
            <ResultadosDeExamesZikaContent detalhamento={zikaConcatenada} />
          </VFlow>
        )}
      </HistoricoSection>
      <HistoricoSection section='A'>
        <VFlow vSpacing={0.5}>
          {hasCiapCidAvaliados && (
            <DataTable
              rows={historico.cidsCiapsAvaliados.sort((a, b) => a.localeCompare(b))}
              columns={[
                {
                  name: 'nome',
                  header: (
                    <Text
                      style={css`
                        color: ${HistoricoSectionStyles['A'].color};
                      `}
                    >
                      Problemas e/ou condições avaliados neste atendimento
                    </Text>
                  ),
                  render: (ciapCid: string) => <Text>{ciapCid}</Text>,
                },
              ]}
            />
          )}
          <GravidezAvaliacaoSection nuGestasPrevias={historico.nuGestasPrevias} nuPartos={historico.nuPartos} />
        </VFlow>
      </HistoricoSection>
      <HistoricoSection section='P'>
        {!hasDadosPlano ? (
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        ) : (
          <VFlow>
            {!isEmpty(historico.examesSolicitados) && (
              <VFlow vSpacing={0.5}>
                <Heading
                  level={5}
                  style={css`
                    color: ${HistoricoSectionStyles['P'].color};
                  `}
                >
                  Exames solicitados
                </Heading>
                <DataTable<DimensaoProcedimento>
                  rows={historico.examesSolicitados.sort((a, b) =>
                    a.descricaoProcedimento.localeCompare(b.descricaoProcedimento)
                  )}
                  columns={[
                    {
                      name: 'nome',
                      header: 'Nome',
                      render: renderExame,
                    },
                  ]}
                />
              </VFlow>
            )}
            {!isEmpty(procedimentosSisab) && (
              <VFlow vSpacing={0.5}>
                <Heading
                  level={5}
                  style={css`
                    color: ${HistoricoSectionStyles['P'].color};
                  `}
                >
                  Intervenções e/ou procedimentos clínicos
                </Heading>
                <HistoricoDetailTable hasLines>
                  <VFlow>
                    {procedimentosSisab
                      ?.sort((a, b) => a.descricaoProcedimento.localeCompare(b.descricaoProcedimento))
                      .map((procedimento) => (
                        <Text key={procedimento.id}>
                          {procedimento.descricaoProcedimento} - {procedimento.codigoProcedimento}
                        </Text>
                      ))}
                  </VFlow>
                </HistoricoDetailTable>
              </VFlow>
            )}
          </VFlow>
        )}
      </HistoricoSection>
      <HistoricoSection section='D'>
        <VFlow>
          {hasProcedimentoSigtap && (
            <VFlow>
              <Heading level={5}>Procedimentos Administrativos (SIGTAP)</Heading>
              <HistoricoDetailTable hasLines>
                <VFlow>
                  {procedimentosSigtap
                    ?.sort((a, b) => a.descricaoProcedimento.localeCompare(b.descricaoProcedimento))
                    .map((procedimento) => (
                      <Text key={procedimento.id}>
                        {procedimento.descricaoProcedimento} - {procedimento.codigoProcedimento}
                      </Text>
                    ))}
                </VFlow>
              </HistoricoDetailTable>
            </VFlow>
          )}
          {historico.dimensaoRacionalidadeSaude && !hasAllAttrsNull(historico.dimensaoRacionalidadeSaude) && (
            <InfoLabel title='Racionalidade em saúde'>{historico.dimensaoRacionalidadeSaude?.descricao}</InfoLabel>
          )}
          {hasCondutas && <InfoLabel title='Conduta'>{stringGrouper(...historico.condutaDesfecho)}</InfoLabel>}
          {hasEncaminhamentos && (
            <InfoLabel title='Encaminhamento'>{stringGrouper(...historico.encaminhamentos)}</InfoLabel>
          )}
        </VFlow>
      </HistoricoSection>
      {(hasNasfPolo || historico.dimensaoModalidadeAd) && (
        <HistoricoSection section='I'>
          <VFlow vSpacing={0.5}>
            {historico.dimensaoModalidadeAd && (
              <InfoLabel title='Atenção domiciliar'>{historico.dimensaoModalidadeAd.descricao}</InfoLabel>
            )}
            {hasNasfPolo && <InfoLabel title='NASF / Polo'>{stringGrouper(...historico.nasfPolo)}</InfoLabel>}
          </VFlow>
        </HistoricoSection>
      )}
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoAtendimentoIndividualPanel)
