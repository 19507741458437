import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { FormProps, SubmitButton } from 'components/form'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React from 'react'
import { FormRenderProps } from 'react-final-form'

import { CuidadoCompartilhadoPlanoModel } from '../model-cuidadocompartilhado'
import { CuidadoCompartilhadoForm } from './CuidadoCompartilhadoForm'
import { CuidadoCompartilhadoFormFields } from './CuidadoCompartilhadoFormFields'

interface AdicionarCuidadoCompartilhadoFormProps {
  onSubmit: FormProps<CuidadoCompartilhadoPlanoModel>['onSubmit']
}

export const AdicionarCuidadoCompartilhadoForm = (props: AdicionarCuidadoCompartilhadoFormProps) => {
  const { onSubmit } = props

  const alert = useAlert()
  const { analytics } = useFirebase()

  const onSubmitSucceeded = () => {
    alert('success', 'Cuidado compartilhado salvo com sucesso.')
    analytics.logEvent('CC_adicionado')
  }

  const renderForm = (renderProps: FormRenderProps<CuidadoCompartilhadoPlanoModel>) => {
    const onCancel = () => {
      renderProps.form.reset()
      renderProps.form.getRegisteredFields().forEach((item) => renderProps.form.resetFieldState(item))
    }

    return (
      <Grid>
        <Cell size={12}>
          <CuidadoCompartilhadoFormFields />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='small' onClick={onCancel}>
              Cancelar
            </Button>
            <SubmitButton kind='primary' size='small' handleSubmit={renderProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return <CuidadoCompartilhadoForm render={renderForm} onSubmit={onSubmit} onSubmitSucceeded={onSubmitSucceeded} />
}
