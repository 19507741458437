import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { createValidator, ErrorObject, required, richTextMaxLength } from 'util/validation'

import { Problema } from '../aside/types/ProblemaModel'
import { EncerrarGestacaoFormModel } from '../pre-natal/model-preNatal'
import { validateEncerrarGestacao } from '../pre-natal/validator-preNatal'
import { AvaliacaoFormModel } from './AvaliacaoForm'
import { avaliacaoObservacaoValidator } from './validator/validatorObservacao'
import { avaliacaoOdontoValidator } from './validator/validatorOdonto'
import { avaliacaoProcedimentosValidator } from './validator/validatorProcedimentos'

const avaliacaoIndividualValidator = (
  dataNascimentoCidadao: string,
  dataAtendimento: Instant,
  problemasAtivosOuLatentesAntigos: Problema[],
  hasPermissionPreNatal: boolean,
  preNatalAtivoHasTipoGravidez: boolean
) =>
  createValidator<AvaliacaoFormModel>(
    {
      texto: [richTextMaxLength(4000)],
      problemasECondicoes: [required],
    },
    (values: AvaliacaoFormModel, errors: ErrorObject<AvaliacaoFormModel>) => {
      errors.encerrarGestacao = validateEncerrarGestacao(
        values,
        problemasAtivosOuLatentesAntigos,
        errors?.encerrarGestacao as ErrorObject<EncerrarGestacaoFormModel>,
        dataNascimentoCidadao,
        dataAtendimento,
        hasPermissionPreNatal,
        preNatalAtivoHasTipoGravidez
      )

      return errors
    }
  )

export const avaliacaoValidator = (
  tipoAtendProf: TipoAtendimentoProfissional,
  dataNascimentoCidadao: string,
  dataAtendimento: Instant,
  problemasAtivosOuLatentesAntigos: Problema[],
  hasPermissionPreNatal: boolean,
  preNatalAtivoHasTipoGravidez: boolean
) => {
  switch (tipoAtendProf) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO: {
      return avaliacaoOdontoValidator()
    }
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS: {
      return avaliacaoProcedimentosValidator()
    }
    case TipoAtendimentoProfissional.OBSERVACAO: {
      return avaliacaoObservacaoValidator()
    }
    default: {
      return avaliacaoIndividualValidator(
        dataNascimentoCidadao,
        dataAtendimento,
        problemasAtivosOuLatentesAntigos,
        hasPermissionPreNatal,
        preNatalAtivoHasTipoGravidez
      )
    }
  }
}
