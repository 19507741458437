/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import {
  AtendimentoProfissionalOdonto,
  EncaminhamentoExternoEspecializado,
  EvolucaoPlano,
  ReceitaMedicamento,
  SolicitacaoExame,
  TipoEncaminhamentoExternoEnum,
} from 'graphql/types.generated'
import { Fragment } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import {
  encaminhamentoExternoRecord,
  historicoSubsectionStyles,
} from 'view/atendimentos/detail/historico/model/model-historico'
import { naoRegistradoNesseAtendimento } from 'view/atendimentos/detail/historico/utils/historicoUtils'
import { PlanoCuidadoModel } from 'view/atendimentos/detail/soap/plano/observacao/model'

import HistoricoEvolucoesOdontologicasPanel from '../../atendimento-odontologico/pec/evolucoes-odontologicas/HistoricoEvolucoesOdontologicasPanel'
import HistoricoPlanoPecCuidadoCompartilhadoList, {
  HistoricoPlanoPecCuidadoCompartilhado,
} from './soap/plano/HistoricoPlanoPecCuidadoCompartilhadoList'
import HistoricoPlanoPecEncaminhamentosEspecializadosPanel from './soap/plano/HistoricoPlanoPecEncaminhamentosEspecializadosPanel'
import HistoricoPlanoPecExamesSolicitadosPanel from './soap/plano/HistoricoPlanoPecExamesSolicitadosPanel'
import HistoricoPlanoPecIntervencoesProcedimentosPanel from './soap/plano/HistoricoPlanoPecIntervencoesProcedimentosPanel'
import HistoricoPlanoPecPrescricaoPanel from './soap/plano/HistoricoPlanoPecPrescricaoPanel'

interface HistoricoPlanoPecPanelProps {
  isAtendProcedimento?: boolean
  isAtendObservacao?: boolean
  evolucaoPlano: EvolucaoPlano
  atendProfOdonto?: AtendimentoProfissionalOdonto
  quantidadeAtestados?: number
  quantidadeOrientacoes?: number
  encaminhamentosEspecializados?: EncaminhamentoExternoEspecializado[]
  encaminhamentos?: TipoEncaminhamentoExternoEnum[]
  prescricaoMedicamentos?: ReceitaMedicamento[]
  examesSolicitados?: SolicitacaoExame[]
  planosDeCuidado?: PlanoCuidadoModel[]
  isOrigemApp?: boolean
  cuidadosCompartilhados?: HistoricoPlanoPecCuidadoCompartilhado[]
}

export default function HistoricoPlanoPecPanel(props: HistoricoPlanoPecPanelProps) {
  const {
    evolucaoPlano,
    atendProfOdonto,
    quantidadeAtestados,
    quantidadeOrientacoes,
    encaminhamentosEspecializados,
    examesSolicitados,
    prescricaoMedicamentos,
    isAtendProcedimento,
    isAtendObservacao,
    isOrigemApp,
    encaminhamentos,
    planosDeCuidado,
    cuidadosCompartilhados,
  } = props

  const descricao = evolucaoPlano?.descricao?.removeTags()

  const possuiOrientacoes = quantidadeOrientacoes > 0

  const possuiAtestados = quantidadeAtestados > 0

  const hasPlano =
    descricao ||
    evolucaoPlano?.intervencoesProcedimentos ||
    atendProfOdonto ||
    examesSolicitados ||
    prescricaoMedicamentos ||
    encaminhamentos ||
    encaminhamentosEspecializados ||
    possuiAtestados ||
    possuiOrientacoes ||
    planosDeCuidado?.isNotEmpty()

  return (
    <Fragment>
      {hasPlano ? (
        <VFlow>
          {isAtendObservacao ? (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Planos de cuidado dessa observação
              </Heading>
              {planosDeCuidado?.map((planoCuidado) => (
                <VFlow vSpacing={0} key={planoCuidado.id}>
                  <Text fontWeight='bold'>
                    <DateTime value={planoCuidado.dataDescricao} format='HH:mm' />
                  </Text>
                  <Text dangerouslySetInnerHTML={{ __html: planoCuidado.descricao }} />
                </VFlow>
              ))}
            </VFlow>
          ) : (
            descricao && <Text dangerouslySetInnerHTML={{ __html: evolucaoPlano?.descricao }} />
          )}

          {evolucaoPlano?.intervencoesProcedimentos && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Intervenções e/ou procedimentos clínicos
              </Heading>
              <HistoricoPlanoPecIntervencoesProcedimentosPanel
                intervencoesProcedimentos={evolucaoPlano.intervencoesProcedimentos}
              />
            </VFlow>
          )}
          {!isAtendProcedimento && !isOrigemApp && (
            <InfoLabel titleStyles={historicoSubsectionStyles.plano} title='Atestado'>
              <Text fontStyle='italic'>
                {quantidadeAtestados > 0
                  ? `${quantidadeAtestados > 1 ? 'Foram emitidos' : 'Foi emitido'} ${'atestado'.pluralizeAndConcatValue(
                      quantidadeAtestados
                    )} neste atendimento.`
                  : 'Não foram emitidos atestados neste atendimento.'}
              </Text>
            </InfoLabel>
          )}
          {examesSolicitados && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Exames solicitados
              </Heading>
              <HistoricoPlanoPecExamesSolicitadosPanel examesSolicitados={examesSolicitados} />
            </VFlow>
          )}
          {prescricaoMedicamentos && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Medicamentos prescritos
              </Heading>
              <HistoricoPlanoPecPrescricaoPanel prescricaoMedicamentos={prescricaoMedicamentos} />
            </VFlow>
          )}

          {!isOrigemApp && (
            <InfoLabel titleStyles={historicoSubsectionStyles.plano} title='Orientações'>
              <Text fontStyle='italic'>
                {quantidadeOrientacoes > 0
                  ? `${quantidadeOrientacoes > 1 ? 'Foram emitidas' : 'Foi emitida'} ${quantidadeOrientacoes} ${
                      quantidadeOrientacoes > 1 ? 'orientações' : 'orientação'
                    } neste atendimento.`
                  : 'Não foram emitidas orientações neste atendimento.'}
              </Text>
            </InfoLabel>
          )}

          {(encaminhamentosEspecializados || encaminhamentos?.length > 0) && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Encaminhamentos
              </Heading>
              {encaminhamentos?.length > 0 && (
                <Text>
                  {stringGrouper(
                    ...encaminhamentos?.map((encaminhamento) => encaminhamentoExternoRecord[encaminhamento])
                  )}
                </Text>
              )}

              {encaminhamentosEspecializados && (
                <HistoricoPlanoPecEncaminhamentosEspecializadosPanel encaminhamentos={encaminhamentosEspecializados} />
              )}
            </VFlow>
          )}
          {cuidadosCompartilhados?.length > 0 && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Compartilhamento de cuidado
              </Heading>
              <HistoricoPlanoPecCuidadoCompartilhadoList cuidadosCompartilhados={cuidadosCompartilhados} />
            </VFlow>
          )}
          {atendProfOdonto && <HistoricoEvolucoesOdontologicasPanel atendProfOdonto={atendProfOdonto} />}
        </VFlow>
      ) : (
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      )}
    </Fragment>
  )
}
