import { Button, Cell, Grid, HFlow, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import {
  CidadaoAtendimentoSelectField,
  CidadaoAtendimentoSelectFieldModel,
  EquipeSelectField,
  Form,
  FormRenderProps,
  SubmitButton,
} from 'components/form'
import { CidadaoAtendimentoPorPropriedadeSelectField } from 'components/form/field/select/CidadaoAtendimentoPorPropriedadeSelectField/CidadaoAtendimentoPorPropriedadeSelectField'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { startOfDay } from 'date-fns'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useApolloClient } from 'graphql/hooks'
import {
  useAgendamentosDiaLazyQuery,
  useBuscaCidadaoPropriedadesQuery,
  useCidadaoLazyQuery,
  useSalvarAtendimentoMutation,
} from 'graphql/hooks.generated'
import { SituacaoAgendadoEnum, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { createValidator, required } from 'util/validation'
import { TipoServicoCheckField } from 'view/configuracoes/municipal/tipo-servico/TipoServicoCheckField'

import { AgendamentosDiaField } from '../agenda/AgendamentosDiaField'
import { convertNovoAtendimentoModelToInput } from '../converter'
import { createNovoAtendimentoCalculations } from '../detail/soap/finalizacao/calculator/calculatorNovoAtendimento'
import { EncaminhamentoInternoFormModel } from '../model'
import { resolveEquipeTooltip, resolveProfissionalTooltip } from '../tooltipResolver'

export interface NovoAtendimentoFormModel extends EncaminhamentoInternoFormModel {
  cidadao?: CidadaoAtendimentoSelectFieldModel
}

const path = metaPath<NovoAtendimentoFormModel>()

const validate = createValidator<NovoAtendimentoFormModel>({
  cidadao: [required],
})

interface ListaAtendimentoFormProps {
  cidadaoId?: string
  updateFilterDefault: () => void
}

const cidadaoCalculator = {
  field: path.cidadao.absolutePath(),
  updates: {
    [path.agendado.absolutePath()]: (value, allValues: NovoAtendimentoFormModel) => {
      if (allValues.agendado) {
        return undefined
      }
    },
  },
}

export function ListaAtendimentoForm(props: ListaAtendimentoFormProps) {
  const { cidadaoId, updateFilterDefault } = props

  const apollo = useApolloClient()
  const alert = useAlert()
  const history = useHistory()
  const { acesso } = useAcessoLotacaoOrEstagio()
  const { getServerTimeNow } = useServerTime()
  const handleRejection = useErrorHandler()
  const { data: dataConfig } = useBuscaCidadaoPropriedadesQuery({ fetchPolicy: 'cache-first' })
  const [salvarAtendimentoMutation, { loading }] = useSalvarAtendimentoMutation()
  const [executeCidadaoQuery, { data: cidadaoData }] = useCidadaoLazyQuery({ variables: { id: cidadaoId } })
  const [executeAgendamentosDiaQuery, { data: agendamentosDiaData }] = useAgendamentosDiaLazyQuery()

  const unidadeSaude = acesso?.unidadeSaude
  const tipoEstabelecimento = unidadeSaude?.tipoEstabelecimento

  const executeAgendamentosDiaCidadaoQuery = useCallback(
    (cidadaoId: ID) => {
      const startOfToday = startOfDay(getServerTimeNow())

      return executeAgendamentosDiaQuery({
        variables: {
          input: {
            cidadaoId,
            dataAgendadoInicio: startOfToday,
            dataAgendadoFim: startOfToday,
            situacao: [SituacaoAgendadoEnum.AGENDADO],
            unidadeSaudeId: unidadeSaude.id,
            isForaDaUbs: false,
            isOrigemAtencaoDomiciliar: false,
          },
        },
      })
    },
    [executeAgendamentosDiaQuery, getServerTimeNow, unidadeSaude.id]
  )

  useEffect(() => {
    if (cidadaoId) {
      executeCidadaoQuery({ variables: { id: cidadaoId } })
      executeAgendamentosDiaCidadaoQuery(cidadaoId)
    }
  }, [cidadaoId, executeCidadaoQuery, executeAgendamentosDiaCidadaoQuery])

  const initialValues: NovoAtendimentoFormModel = useMemo(
    () => ({
      cidadao: cidadaoId && cidadaoData && { ...cidadaoData.cidadao, presenteListaAtendimento: false },
    }),
    [cidadaoData, cidadaoId]
  )

  const handleCidadaoChange = (newValue: CidadaoAtendimentoSelectFieldModel) => {
    if (newValue && newValue.id) executeAgendamentosDiaCidadaoQuery(newValue.id)
  }

  const handleSubmit = (value: NovoAtendimentoFormModel, formApi: FormApi) => {
    !value.cidadao.dataNascimento
      ? alert(
          'danger',
          'Não é possível adicionar o cidadão. Atualize o cadastro do cidadão informando a data de nascimento.'
        )
      : save(value, formApi)
  }

  const save = (value: NovoAtendimentoFormModel, formApi: FormApi) =>
    salvarAtendimentoMutation({
      variables: {
        input: convertNovoAtendimentoModelToInput(value),
      },
    })
      .then((res) => onSubmitSuccess(res, formApi))
      .catch(handleRejection)

  const onSubmitSuccess = (result, formApi: FormApi) => {
    alert('success', `Cidadão foi adicionado com sucesso.`)
    formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
    setTimeout(formApi.reset)
    updateFilterDefault()

    history.push('/lista-atendimento')
    return result
  }

  const renderForm = (formProps: FormRenderProps<NovoAtendimentoFormModel>) => {
    const values = formProps.values

    return (
      <Grid>
        <Cell size={5}>
          <VFlow>
            {dataConfig.info.buscaCidadaoPorPropriedadesEnabled ? (
              <CidadaoAtendimentoPorPropriedadeSelectField name={path.cidadao} />
            ) : (
              <CidadaoAtendimentoSelectField
                name={path.cidadao}
                ativo
                obito={false}
                label='Cidadão'
                onChange={handleCidadaoChange}
                addCidadaoCallbackUrl='/lista-atendimento'
                required
              />
            )}

            {values?.cidadao && (
              <AgendamentosDiaField
                name={path.agendado}
                agendamentosDia={values?.cidadao && agendamentosDiaData?.agendados?.content}
              />
            )}

            <Tooltip text={resolveProfissionalTooltip(values)}>
              <LotacaoAndEstagioSelectField
                name={path.responsavel}
                includeEstagios
                label='Profissional'
                unidadeSaudeId={unidadeSaude.id}
                disabled={(values?.equipe && !values?.responsavel) || !!values?.agendado}
              />
            </Tooltip>

            {tipoEstabelecimento !== TipoEstabelecimentoEnum.CEO && (
              <Tooltip text={resolveEquipeTooltip(values)}>
                <EquipeSelectField
                  name={path.equipe}
                  label='Equipe'
                  unidadeSaudeId={unidadeSaude.id}
                  disabled={!!values?.responsavel || !!values?.agendado}
                />
              </Tooltip>
            )}
          </VFlow>
        </Cell>

        <Cell size={7}>
          <div>
            <TipoServicoCheckField name={path.tiposServico} />
          </div>
        </Cell>

        <Cell alignSelf='flex-end' size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='medium' onClick={formProps.form.reset}>
              Limpar campos
            </Button>
            <SubmitButton size='medium' loading={loading} disabled={loading} handleSubmit={formProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  const decorator = useMemo(
    () => createDecorator(...createNovoAtendimentoCalculations(path, tipoEstabelecimento, apollo), cidadaoCalculator),
    [apollo, tipoEstabelecimento]
  )

  if (isObjectDeepEmpty(dataConfig)) {
    return null
  }

  return (
    <Form<NovoAtendimentoFormModel>
      onSubmit={handleSubmit}
      render={renderForm}
      decorators={[decorator]}
      validate={validate}
      initialValues={initialValues}
    />
  )
}
