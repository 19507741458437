import { isEmpty, Text } from 'bold-ui'
import { UltimoArquivoQuery } from 'graphql/types.generated'
import React from 'react'
import StatusArquivoLabel from 'view/arquivo/StatusArquivoLabel'

interface UltimoRelatorioStatusProps {
  data: UltimoArquivoQuery
  loading: boolean
}

export default function UltimoRelatorioStatus({ data, loading }: UltimoRelatorioStatusProps) {
  return !isEmpty(data.ultimoArquivo) ? (
    <StatusArquivoLabel arquivo={data.ultimoArquivo} isApenasUltimoRelatorio />
  ) : (
    !loading && <Text>Nenhum relatório gerado nos últimos 7 dias</Text>
  )
}
