import { MedicamentoCatmatSelectModel } from 'components/form/field/select/MedicamentoCatMatSelectField'
import { tiposReceitaRecord } from 'components/form/field/select/TipoReceitaSelectField'
import { ViaAdministracaoSelectModel } from 'components/form/field/select/ViaAdministracaoSelectField'
import {
  DisponibilidadeHorusEnum,
  ListaMedicamentoEnum,
  TipoFrequencia,
  TipoReceitaEnum,
  UnidadeMedidaTempoEnum,
} from 'graphql/types.generated'
import { turnoByStringRecord } from 'types/enums'
import { dateAsYyyyMmDd, toDate } from 'util/date/formatDate'
import { parseNumber } from 'util/validation'
import { v4 as uuidv4 } from 'uuid'

import { PrescricaoDigitalTableModel } from '../components/PrescricaoDigitalTable'
import { MedicamentoToPrintDto } from '../modal/impressao/ImpressaoMedicamentosInput'
import { PrescricaoDigitalFormModel } from '../modal/prescricao-digital/model-prescricaoDigital'
import { MedicamentoFormModel, ReceitaMedicamentoQueryModel } from '../model'
import { calculateDataFimTratamento, createDoseReceitaLabel, createFrequenciaReceitaLabel } from './utils-prescricao'

export interface PrescricaoAtendimentoBodyModel {
  principioAtivo: string
  concentracao: string
  formaFarmaceutica: string
  tipoReceita: TipoReceitaEnum
  posologia: string
  viaAdministracao: ViaAdministracaoSelectModel
  recomendacoes: string
  dataInicioTratamento: LocalDate
  dataFimTratamento: LocalDate
  duracao: number
  escalaDuracao: UnidadeMedidaTempoEnum
  disponibilidade: DisponibilidadeHorusEnum
  usoContinuo: boolean
}

export function prescricaoAtendHeaderConverter(medicamentos: MedicamentoFormModel[]): string {
  return medicamentos
    ?.map((medicamento) => {
      const principioAtivo = medicamento.registroManual
        ? medicamento.principioAtivoText
        : medicamento.principioAtivoCombo.medicamento.principioAtivo
      const concentracao = medicamento.registroManual
        ? medicamento.concentracaoText
        : medicamento.principioAtivoCombo.medicamento.concentracao
      return `${principioAtivo} ${concentracao ?? ''}`
    })
    .join(' | ')
}

export function prescricaoAtendBodyConverter(medicamentos: MedicamentoFormModel[]): PrescricaoAtendimentoBodyModel[] {
  return medicamentos.map((medicamento) => {
    const principioAtivo: string = medicamento.registroManual
      ? medicamento.principioAtivoText
      : medicamento.principioAtivoCombo.principioAtivo.nome
    const concentracao: string = medicamento.registroManual
      ? medicamento.concentracaoText
      : medicamento.principioAtivoCombo.medicamento.concentracao
    const formaFarmaceutica: string = medicamento.registroManual
      ? medicamento.formaFarmaceutica.nome
      : medicamento.principioAtivoCombo.medicamento.formaFarmaceutica.nome
    const tipoReceita: TipoReceitaEnum = medicamento.registroManual
      ? medicamento.tipoReceita.tipoReceitaEnum
      : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita
    return {
      principioAtivo,
      concentracao,
      formaFarmaceutica,
      tipoReceita,
      posologia: medicamento.posologia,
      viaAdministracao: medicamento.viaAdministracao,
      recomendacoes: medicamento.recomendacoes,
      dataInicioTratamento: medicamento.dataInicioTratamento,
      dataFimTratamento: medicamento.dataFimTratamento,
      duracao: medicamento.duracao,
      escalaDuracao: medicamento.escalaDuracao,
      disponibilidade: medicamento.disponibilidade,
      usoContinuo: medicamento.usoContinuo,
    } as PrescricaoAtendimentoBodyModel
  })
}

export function convertReceitaMedicamentoToMedicamento(
  receita: ReceitaMedicamentoQueryModel,
  isVersaoAnterior: boolean,
  isReplicacao: boolean,
  dataInicioAtendimento: Instant
): MedicamentoFormModel {
  const medicamento = receita.medicamento
  const medicamentoCatmat: MedicamentoCatmatSelectModel = { ...medicamento.medicamentoCatmat, medicamento }

  const localDateInicioTratamento = dateAsYyyyMmDd(toDate(dataInicioAtendimento))

  const dataFimTratamento = calculateDataFimTratamento(
    receita.quantidadeReceitada,
    localDateInicioTratamento,
    receita.medidaTempoTratamento
  )

  return {
    id: uuidv4(),
    editingId: isReplicacao ? null : receita.id,
    registroManual: receita.registroManual,
    principioAtivoCombo: !receita.registroManual && medicamentoCatmat,
    principioAtivoText: receita.registroManual && receita.medicamento.principioAtivo,
    concentracaoText: receita.registroManual && receita.medicamento.concentracao,
    viaAdministracao: receita.aplicacaoMedicamento,
    tipoReceita: tiposReceitaRecord[receita.tipoReceita],
    formaFarmaceutica: receita.medicamento.formaFarmaceutica,
    dose: isVersaoAnterior ? '' : receita.dose,
    qtDose: receita.qtDose?.toString().replace('.', ','),
    unidadeMedidaDose: receita.unidadeMedidaDose,
    doseUnica: receita.doseUnica,
    tipoFrequencia: receita.tipoFrequenciaDose ?? TipoFrequencia.INTERVALO,
    intervaloDose: receita.tipoFrequenciaDose === TipoFrequencia.INTERVALO ? parseNumber(receita.frequenciaDose) : null,
    frequenciaDose: isVersaoAnterior
      ? ''
      : receita.tipoFrequenciaDose === TipoFrequencia.FREQUENCIA
      ? parseNumber(receita.frequenciaDose)
      : null,
    quantidadePeriodoFrequenciaTurno: receita.qtPeriodoFrequencia,
    unidadeMedidaTempoFrequenciaTurno: receita.medidaTempoFrequencia,
    turno: receita.tipoFrequenciaDose !== TipoFrequencia.TURNO ? null : turnoByStringRecord[receita.frequenciaDose],
    posologia: receita.posologia,
    dataInicioTratamento: localDateInicioTratamento,
    dataFimTratamento: dataFimTratamento ? dateAsYyyyMmDd(dataFimTratamento) : null,
    duracao: receita.usoContinuo ? null : receita.qtDuracaoTratamento,
    escalaDuracao: receita.usoContinuo
      ? UnidadeMedidaTempoEnum.INDETERMINADO
      : !isVersaoAnterior
      ? receita.medidaTempoTratamento
      : UnidadeMedidaTempoEnum.DIAS,
    quantidade: receita.quantidadeReceitada,
    usoContinuo: receita.usoContinuo,
    recomendacoes: receita.recomendacoes,
    unidadeFornecimento: receita.medicamento.unidadeFornecimento,
    isVersaoAnterior,
  } as MedicamentoFormModel
}

export function convertMedicamentoToPrintDto(medicamento: MedicamentoFormModel): MedicamentoToPrintDto {
  return {
    id: medicamento.id,
    medicamentoId: !medicamento.registroManual ? medicamento.principioAtivoCombo.medicamento.id : '',
    principioAtivo: !medicamento.registroManual
      ? medicamento.principioAtivoCombo.principioAtivo.nome
      : medicamento.principioAtivoText,
    registroManual: medicamento.registroManual,
    categoria: medicamento?.principioAtivoCombo?.principioAtivo?.listaMaterial?.tipo,
    volume: !medicamento.registroManual ? medicamento.principioAtivoCombo.volume : '',
    concentracao: !medicamento.registroManual
      ? medicamento.principioAtivoCombo.medicamento.concentracao
      : !!medicamento.concentracaoText
      ? medicamento.concentracaoText
      : '',
    formaFarmaceutica: medicamento.registroManual
      ? medicamento.formaFarmaceutica.id
      : medicamento.principioAtivoCombo.medicamento.formaFarmaceutica.id,
    codigoCatMat: !medicamento.registroManual ? medicamento.principioAtivoCombo.codigoCatMat : '',
    codigoRename: !medicamento.registroManual ? medicamento.principioAtivoCombo.codigoRename : '',
    tipoReceita: medicamento.registroManual
      ? medicamento.tipoReceita.tipoReceitaEnum
      : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita,
    unidadeFornecimento: !medicamento.registroManual ? medicamento.principioAtivoCombo.unidadeFornecimento.id : '',
    unidadeFornecimentoDescricao: medicamento.registroManual
      ? medicamento.unidadeFornecimento
      : medicamento.principioAtivoCombo.unidadeFornecimento.nome,
    disponibilidadeHorus: !medicamento.registroManual
      ? medicamento.disponibilidade
      : DisponibilidadeHorusEnum.DESABILITADO_MANUAL,
    quantidade: medicamento.quantidade,
    periodoTratamento: medicamento.escalaDuracao,
    duracaoTratamento: medicamento.duracao,
    tipoFrequencia: medicamento.tipoFrequencia,
    frequenciaDose: createFrequenciaReceitaLabel(
      medicamento.tipoFrequencia,
      medicamento.intervaloDose,
      medicamento.frequenciaDose,
      medicamento.quantidadePeriodoFrequenciaTurno,
      medicamento.unidadeMedidaTempoFrequenciaTurno,
      medicamento.turno,
      medicamento.doseUnica
    ),
    recomendacoes: medicamento.recomendacoes,
    posologia: medicamento.posologia,
    dataInicio: medicamento.dataInicioTratamento,
    dataConclusao: medicamento.dataFimTratamento,
    unidadeMedidaDuracao: medicamento.escalaDuracao,
    usoContinuo: medicamento.usoContinuo,
    doseUnica: Boolean(medicamento.doseUnica),
    dose: createDoseReceitaLabel(medicamento.unidadeMedidaDose, medicamento.qtDose),
    viaAdministracao: medicamento.viaAdministracao.nome,
  } as MedicamentoToPrintDto
}

export function processTextoMedicamentos(
  medicamentosPrescricao: MedicamentoFormModel[],
  codigoAcessoPrescricao: string
): string {
  return medicamentosPrescricao
    .filter((medicamento) => medicamento.codigoPrescricaoDigital === codigoAcessoPrescricao)
    .map((medicamento) => {
      const principioAtivo: string = medicamento.registroManual
        ? medicamento.principioAtivoText
        : medicamento.principioAtivoCombo.principioAtivo.nome
      const concentracao: string = medicamento.registroManual
        ? medicamento.concentracaoText
        : medicamento.principioAtivoCombo.medicamento.concentracao
      return `${principioAtivo} ${concentracao ?? ''}`
    })
    .join(' | ')
}

export function prescricaoDigitalTableConverter(
  prescricaoDigital: PrescricaoDigitalFormModel
): PrescricaoDigitalTableModel[] {
  return Object.entries(prescricaoDigital).map(([codigoAcesso, prescricao]) => ({
    codigoPrescricaoDigital: codigoAcesso,
    textoMedicamentos: prescricao.textoMedicamentos,
    prescricaoInvalidada: prescricao.prescricaoInvalidada,
    emailEnvio: prescricao.emailEnvio,
    dataAssinatura: prescricao.dataAssinatura,
  }))
}

export function resolvePrincipioAtivo(medicamento) {
  return medicamento.registroManual
    ? medicamento.principioAtivoText
    : medicamento.principioAtivoCombo.principioAtivo.nome
}

export function resolveConcentracao(medicamento) {
  return !medicamento.registroManual
    ? medicamento.principioAtivoCombo.medicamento.concentracao
    : medicamento.concentracaoText
    ? medicamento.concentracaoText
    : ''
}

export function resolveTipoReceita(medicamento) {
  return medicamento.registroManual
    ? medicamento.tipoReceita.tipoReceitaEnum
    : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita
}

export function resolveMedicamento(medicamento) {
  const principioAtivo = resolvePrincipioAtivo(medicamento)
  const concentracao = resolveConcentracao(medicamento)
  const tipoReceita = resolveTipoReceita(medicamento)

  return { principioAtivo, concentracao, tipoReceita }
}

export const filtrarMedicamentos = (
  medicamentos: MedicamentoFormModel[],
  categoriasNaoPermitidas: ListaMedicamentoEnum[],
  tiposReceitaNaoPermitidos: TipoReceitaEnum[]
) => {
  return medicamentos.reduce(
    (acc, medicamento) => {
      const isMedicamentoPermitido = medicamento.registroManual
        ? !tiposReceitaNaoPermitidos?.includes(medicamento.tipoReceita.tipoReceitaEnum)
        : !categoriasNaoPermitidas?.includes(
            medicamento?.principioAtivoCombo?.principioAtivo?.listaMaterial?.tipo as ListaMedicamentoEnum
          )

      if (isMedicamentoPermitido) {
        acc.medicamentosPermitidos.push(medicamento)
      } else {
        acc.medicamentosNaoPermitidos.push(medicamento)
      }
      return acc
    },
    { medicamentosPermitidos: [], medicamentosNaoPermitidos: [] }
  )
}
