/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, InfoLabel, Tag, Text, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { HLabel } from 'components/HLabel'
import { IconLabel } from 'components/IconLabel'
import { Cpf, Telefone } from 'components/label'
import theme from 'config/theme'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { NIVEL_SUPERIOR } from 'types/NivelCbo'

import { GarantiaAcessoItem, LabelEnviarGarantiaAcessoRecord } from './model-garantiaAcesso'

interface GarantiaAcessoTablePanelProps {
  row: GarantiaAcessoItem
}

export const GarantiaAcessoTablePanel = (props: GarantiaAcessoTablePanelProps) => {
  const {
    row: { cidadao, cuidadoCompartilhadoEvolucao, motivoEspera, qtdCidadaoPresente },
  } = props

  const { hasCboAuth } = useSession()
  const vezesString = qtdCidadaoPresente > 1 ? 'vezes' : 'vez'
  const hasPermissaoVisualizarMotivo = !cuidadoCompartilhadoEvolucao || hasCboAuth(NIVEL_SUPERIOR)
  const isAgendamentoUnicoProfissionalComCidadao =
    cuidadoCompartilhadoEvolucao?.condutaAgendamento ===
    CondutaCuidadoCompartilhadoEnum.AGENDADO_CONSULTA_CIDADAO_PRESENCIAL

  return (
    <Grid gap={0} style={styles.grid}>
      <Cell size={12}>
        <VFlow vSpacing={0.5}>
          <VFlow vSpacing={0}>
            <Text fontWeight='bold' fontSize={0.875}>
              Sobre a demanda
            </Text>
            {cuidadoCompartilhadoEvolucao && (
              <HFlow>
                <Text fontSize={0.875}>
                  {LabelEnviarGarantiaAcessoRecord[cuidadoCompartilhadoEvolucao.condutaAgendamento]}
                </Text>
                {cuidadoCompartilhadoEvolucao.condutaAgendamento ===
                  CondutaCuidadoCompartilhadoEnum.AGENDADO_TELEINTERCONSULTA_COM_CIDADAO && (
                  <Tag type='normal' style={styles.cuidadoCompartilhadoVideochamada}>
                    via Videochamada e-SUS APS
                  </Tag>
                )}
              </HFlow>
            )}
          </VFlow>
          {hasPermissaoVisualizarMotivo && (
            <InfoLabel title='Motivo' childStyles={styles.motivo}>
              {motivoEspera}
            </InfoLabel>
          )}
        </VFlow>
      </Cell>

      <Cell flexGrow={1}>
        <VFlow vSpacing={0}>
          <Text fontWeight='bold' fontSize={0.875}>
            Cidadão
          </Text>
          <HFlow>
            <Text fontWeight='bold'>{(cidadao.nomeSocial ?? cidadao.nome).titleCase()}</Text>
            <IconLabel icon='infoCircleOutline' text={`Presente ${qtdCidadaoPresente} ${vezesString} na lista`} />
          </HFlow>
          {!cidadao.cpf && !cidadao.cns ? (
            <Text>Cidadão sem CPF/CNS cadastrado</Text>
          ) : (
            <HFlow>
              {cidadao.cpf && (
                <HLabel title='CPF'>
                  <Cpf value={cidadao.cpf} />
                </HLabel>
              )}
              {cidadao.cns && <HLabel title='CNS'>{cidadao.cns}</HLabel>}
            </HFlow>
          )}
          <HLabel title='Telefones' style={styles.telefones}>
            {cidadao.telefoneContato && (
              <Text>
                <Telefone value={cidadao.telefoneContato} />
              </Text>
            )}
            {cidadao.telefoneCelular && (
              <Text>
                <Telefone value={cidadao.telefoneCelular} />
              </Text>
            )}
            {cidadao.telefoneResidencial && (
              <Text>
                <Telefone value={cidadao.telefoneResidencial} />
              </Text>
            )}
          </HLabel>
        </VFlow>
      </Cell>
      {cuidadoCompartilhadoEvolucao && (
        <Cell size={8} flexGrow={1} flexBasis={isAgendamentoUnicoProfissionalComCidadao ? 'auto' : '100%'}>
          <HFlow hSpacing={2}>
            <VFlow vSpacing={0}>
              <Text fontWeight='bold' fontSize={0.875}>
                {isAgendamentoUnicoProfissionalComCidadao ? 'Profissional' : 'Profissional anfitrião'}
              </Text>
              <Text fontWeight='bold'>
                {cuidadoCompartilhadoEvolucao.lotacaoExecutante.profissional.nome.titleCase()}
              </Text>
              <HFlow>
                <HLabel title='Equipe'>{cuidadoCompartilhadoEvolucao.lotacaoExecutante.equipe.nome}</HLabel>
                <HLabel title='CBO'>{cuidadoCompartilhadoEvolucao.lotacaoExecutante.cbo.nome.titleCase()}</HLabel>
              </HFlow>
              <HLabel title='E-mail'>{cuidadoCompartilhadoEvolucao.lotacaoExecutante.profissional.email}</HLabel>
            </VFlow>
            {!isAgendamentoUnicoProfissionalComCidadao && (
              <VFlow vSpacing={0}>
                <Text fontWeight='bold' fontSize={0.875}>
                  Profissional convidado
                </Text>
                <Text fontWeight='bold'>
                  {cuidadoCompartilhadoEvolucao.cuidadoCompartilhado.lotacaoSolicitante.profissional.nome.titleCase()}
                </Text>
                <HFlow>
                  <HLabel title='Equipe'>
                    {cuidadoCompartilhadoEvolucao.cuidadoCompartilhado.lotacaoSolicitante.equipe.nome}
                  </HLabel>
                  <HLabel title='CBO'>
                    {cuidadoCompartilhadoEvolucao.cuidadoCompartilhado.lotacaoSolicitante.cbo.nome.titleCase()}
                  </HLabel>
                </HFlow>
                <HLabel title='E-mail'>
                  {/* TODO Foundation - Está em análise se o email será apresentado ou não, visto que, ele é ofuscado */}
                  {cuidadoCompartilhadoEvolucao.cuidadoCompartilhado.lotacaoSolicitante.profissional.email}
                </HLabel>
              </VFlow>
            )}
          </HFlow>
        </Cell>
      )}
    </Grid>
  )
}

const styles = {
  grid: css`
    background: ${theme.pallete.gray.c90};
    padding: 1rem;
  `,
  cuidadoCompartilhadoVideochamada: css`
    background: ${theme.pallete.gray.c40};
    color: ${theme.pallete.gray.c100};
  `,
  telefones: css`
    & > :last-child {
      & > :not(:last-child):after {
        content: ' | ';
      }
    }
  `,
  motivo: css`
    word-break: break-word;
  `,
}
