/** @jsx jsx */
import { QueryLazyOptions } from '@apollo/react-hooks'
import { css, jsx } from '@emotion/core'
import { Checkbox, colors, Text, VFlow } from 'bold-ui'
import { RegistrosDoseVacinacaoQueryVariables, SexoEnum } from 'graphql/types.generated'
import CovidAccordionImunobiologicoRow from 'view/atendimentos/atendimento-vacinacao/components/CovidAccordionImunobiologicoRow'
import { OutrosImunobiologicosButtonGroup } from 'view/atendimentos/atendimento-vacinacao/OutrosImunobiologicosButtonGroup'
import { covidImunosRecord } from 'view/lote-imunobiologico/model'

import { InfoCardModalVacinacao, RegistroVacinacaoFormInput } from '../../vacinacao/VacinacaoCalendarioView'
import { ImunobiologicoRow, ImunobiologicoRowProps } from './ImunobiologicoRow'
import { LegendaCalendarioVacinal } from './LegendaCalendarioVacinal'

export interface VacinacaoPanelProps {
  outrosImunobiologicos?: boolean
  imunobiologicos: ImunobiologicoRowProps[]
  handleOnDoseClicked: (info: InfoCardModalVacinacao) => void
  registrosDoseLazyQuery: (options?: QueryLazyOptions<RegistrosDoseVacinacaoQueryVariables>) => void
  handleOnSubmit?(input: RegistroVacinacaoFormInput): Promise<void>
  dataAtendimento?: Instant
  dataNascimento?: string
  sexo?: SexoEnum
  gestante?: boolean
  mostrarCalendarioCompleto?: boolean
  setMostrarCalendarioCompleto?: (value: boolean) => void
  isAcompanhamentoVacinal?: boolean
  readOnly?: boolean
}

export function VacinacaoPanel(props: VacinacaoPanelProps) {
  const {
    outrosImunobiologicos = false,
    imunobiologicos,
    handleOnSubmit,
    registrosDoseLazyQuery,
    dataAtendimento,
    dataNascimento,
    sexo,
    gestante,
    mostrarCalendarioCompleto,
    setMostrarCalendarioCompleto,
    isAcompanhamentoVacinal,
    readOnly = false,
  } = props

  const imunobiologicosCovid = []
  const imunobiologicosSemCovid = []

  imunobiologicos.forEach((value) =>
    covidImunosRecord[value.model.imunobiologicoId]
      ? imunobiologicosCovid.push(value)
      : imunobiologicosSemCovid.push(value)
  )

  return (
    <VFlow vSpacing={0.25}>
      {outrosImunobiologicos && !readOnly ? (
        <OutrosImunobiologicosButtonGroup
          handleOnSubmit={handleOnSubmit}
          dataAtendimento={dataAtendimento}
          dataNascimento={dataNascimento}
          sexo={sexo}
          gestante={gestante}
          isAcompanhamentoVacinal={isAcompanhamentoVacinal}
        />
      ) : (
        !outrosImunobiologicos && (
          <div
            css={css`
              background-color: ${colors.gray.c90};
              padding: 0.5rem 1rem;
            `}
          >
            <Checkbox
              label='Mostrar calendário nacional completo'
              onChange={() => setMostrarCalendarioCompleto(!mostrarCalendarioCompleto)}
              checked={mostrarCalendarioCompleto}
            />
          </div>
        )
      )}

      {imunobiologicosCovid?.length > 0 && (
        <CovidAccordionImunobiologicoRow
          imunobiologicosCovid={imunobiologicosCovid}
          mostrarCalendarioCompleto={mostrarCalendarioCompleto}
          handleOnDoseClicked={props.handleOnDoseClicked}
          registrosDoseLazyQuery={registrosDoseLazyQuery}
          isAcompanhamentoVacinal={isAcompanhamentoVacinal}
          readOnly={readOnly}
        />
      )}

      {imunobiologicosSemCovid.length > 0 && (
        <VFlow vSpacing={0.25}>
          {imunobiologicosSemCovid
            .filter((value) =>
              value.model.doses.find((dose) => dose.model.mostrarNoCalendarioDoGrupo || mostrarCalendarioCompleto)
            )
            .map((value) => (
              <ImunobiologicoRow
                key={value.model.imunobiologicoId}
                mostrarCalendarioCompleto={mostrarCalendarioCompleto}
                handleOnDoseClicked={props.handleOnDoseClicked}
                registrosDoseLazyQuery={registrosDoseLazyQuery}
                isAcompanhamentoVacinal={isAcompanhamentoVacinal}
                readOnly={readOnly}
                {...value}
              />
            ))}

          <LegendaCalendarioVacinal showRegistradaNesteAtendimento={readOnly} />
        </VFlow>
      )}

      {imunobiologicos.length === 0 && (
        <div style={{ margin: '0.5rem 0rem' }}>
          <Text fontStyle='italic'>Nenhum outro imunobiológico registrado para o cidadão.</Text>
        </div>
      )}
    </VFlow>
  )
}
