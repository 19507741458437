/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, ButtonGroup, Cell, Grid, Heading, HFlow, InfoLabel, Text, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import {
  CheckboxField,
  DateField,
  ErrorField,
  HorarioAgendaSelectField,
  TelefoneField,
  TextAreaField,
  TextField,
} from 'components/form'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { IconLabel } from 'components/IconLabel'
import { InfoIcon } from 'components/InfoIcon'
import { Telefone } from 'components/label'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { addMonths, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { CondutaCuidadoCompartilhadoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { isEmpty } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { InserirVideochamadaAgendamentoSection } from 'view/agenda/components/InserirVideochamadaAgendamentoSection'
import {
  AgendarConsultaCuidadoCompartilhadoFieldsModel,
  CondutaGroupEnum,
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
  EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel,
  TipoAgendamentoCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { getLotacoesConsultaCompartilhadaCuidadoCompartilhado } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'
import { EnviarGarantiaAcessoCuidadoCompartilhadoField } from './EnviarGarantiaAcessoCuidadoCompartilhadoField'
import { EnviarGarantiaAcessoCuidadoCompartilhadoPanel } from './EnviarGarantiaAcessoCuidadoCompartilhadoPanel'

interface AgendarConsultaCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
}

export const AgendarConsultaCuidadoCompartilhadoField = (props: AgendarConsultaCuidadoCompartilhadoFieldProps) => {
  const { path, cuidadoCompartilhado, videochamadaUuidState } = props

  const {
    PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED,
    PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED,
    PILOTO_VIDEOCHAMADA_2_ENABLED,
    GARANTIA_ACESSO_ENABLED,
  } = useFlags()

  const {
    input: { value: garantiaAcessoValue },
  } = usePecField({ name: path.garantiaAcesso, subscription: { value: true } })

  const garantiaAcessoInput = garantiaAcessoValue as EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel

  const isGarantiaAcesso =
    PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && GARANTIA_ACESSO_ENABLED && !isEmpty(garantiaAcessoInput)

  const name = isGarantiaAcesso ? path.garantiaAcesso : path.agendamento
  const { getServerTimeNow } = useServerTime()
  const { acesso: lotacao } = useAcessoLotacaoOrEstagio()
  const serverTime = getServerTimeNow()
  const { loading: loadingConfiguracoesVideochamada, videochamadasEnabled } = useVideochamadasConfiguration()
  const [videochamadaUuid, setVideochamadaUuid] = videochamadaUuidState

  const {
    input: { value: agendarCuidadoValue },
  } = usePecField({ name, subscription: { value: true } })

  const {
    input: { value: tipoAgendamentoValue, onChange: onChangeTipoAgendamento },
  } = usePecField({ name: path.agendamento.tipoAgendamento, subscription: { value: true } })

  const isConsultaCidadaoPresencial = tipoAgendamentoValue === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO
  const isConsultaCompartilhada = tipoAgendamentoValue === TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA

  const { lotacaoConvidada } = getLotacoesConsultaCompartilhadaCuidadoCompartilhado(lotacao.id, cuidadoCompartilhado)

  const cidadaoHasTelefone = !isUndefinedOrNull(cuidadoCompartilhado.cidadao.telefoneCelular)
  const cidadaoHasEmail = !isUndefinedOrNull(cuidadoCompartilhado.cidadao.email)

  const isCheckedCidadaoPresenteConsultaCompartilhada = !!agendarCuidadoValue?.consultaCompartilhada?.isCidadaoPresente
  const isVideoChamadaFlagEnabled = PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && PILOTO_VIDEOCHAMADA_2_ENABLED

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<
      AgendarConsultaCuidadoCompartilhadoFieldsModel | EnviarGarantiaAcessoCuidadoCompartilhadoFieldsModel
    >
      name={name}
      pathCondutaGroup={path.condutaGroup}
      value={isGarantiaAcesso ? CondutaGroupEnum.ENVIAR_GARANTIA_ACESSO : CondutaGroupEnum.AGENDAR_CONSULTA}
    >
      <VFlow>
        {isVideoChamadaFlagEnabled ? (
          <ButtonGroup>
            <Button
              kind={isConsultaCidadaoPresencial ? 'primary' : 'normal'}
              size='small'
              onClick={() => onChangeTipoAgendamento(TipoAgendamentoCuidadoCompartilhado.CONSULTA_COM_CIDADAO)}
              disabled={isGarantiaAcesso}
            >
              Consulta com o cidadão
            </Button>
            <Button
              kind={isConsultaCompartilhada ? 'primary' : 'normal'}
              size='small'
              onClick={() => onChangeTipoAgendamento(TipoAgendamentoCuidadoCompartilhado.CONSULTA_COMPARTILHADA)}
              disabled={isGarantiaAcesso}
            >
              Consulta entre profissionais
            </Button>
          </ButtonGroup>
        ) : (
          <Heading level={3}>Consulta com o cidadão</Heading>
        )}

        {!isGarantiaAcesso ? (
          <VFlow>
            {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && GARANTIA_ACESSO_ENABLED && (
              <Alert type='info' inline style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
                É possível enviar demandas de agendamentos com a <strong>presença do cidadão</strong> para o módulo de
                Garantia do acesso, caso não haja disponibilidade de agenda no momento.
              </Alert>
            )}
            {isConsultaCidadaoPresencial && (
              <VFlow>
                <ErrorField name={FORM_ERROR} inline={false} type='alert' />
                <ErrorField name={path.agendamento.consultaComCidadao} inline={false} type='alert' ignoreObjectError />
                <Grid>
                  <Cell size={6} lg={3}>
                    <DateField
                      name={path.agendamento.consultaComCidadao.data}
                      label='Data do agendamento'
                      minDate={serverTime}
                      maxDate={addMonths(serverTime, 12)}
                      clearable
                      required
                    />
                  </Cell>
                  <Cell size={6} lg={3}>
                    <HorarioAgendaSelectField
                      name={path.agendamento.consultaComCidadao.horario}
                      lotacaoId={lotacao.id}
                      dia={
                        agendarCuidadoValue.consultaComCidadao?.data
                          ? parseISO(agendarCuidadoValue.consultaComCidadao?.data)
                          : undefined
                      }
                      disabled={!agendarCuidadoValue.consultaComCidadao?.data}
                      labels={{ inicial: 'Horário do agendamento' }}
                      required
                    />
                  </Cell>

                  {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && GARANTIA_ACESSO_ENABLED && (
                    <Cell size={6} lg={3}>
                      <EnviarGarantiaAcessoCuidadoCompartilhadoField
                        name={path.garantiaAcesso}
                        pathCondutaGroup={path.condutaGroup}
                        tipoAgendamento={CondutaCuidadoCompartilhadoEnum.AGENDADO_CONSULTA_CIDADAO_PRESENCIAL}
                        cidadaoAceitaAtendTic={cuidadoCompartilhado.cidadaoAceitaAtendTic}
                      />
                    </Cell>
                  )}

                  <Cell size={12}>
                    <TextAreaField
                      name={path.agendamento.consultaComCidadao.observacoes}
                      label='Observações'
                      placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
                      maxLength={200}
                      style={styles.textArea}
                    />
                  </Cell>
                </Grid>
              </VFlow>
            )}
            {isVideoChamadaFlagEnabled && isConsultaCompartilhada && (
              <VFlow>
                <ErrorField name={FORM_ERROR} inline={false} type='alert' />
                <ErrorField
                  name={path.agendamento.consultaCompartilhada}
                  inline={false}
                  type='alert'
                  ignoreObjectError
                />
                <Grid>
                  <Cell size={6} lg={3}>
                    <DateField
                      name={path.agendamento.consultaCompartilhada.data}
                      label='Data do agendamento'
                      minDate={serverTime}
                      maxDate={addMonths(serverTime, 12)}
                      clearable
                      required
                    />
                  </Cell>

                  <Cell size={6} lg={3}>
                    <HorarioAgendaSelectField
                      name={path.agendamento.consultaCompartilhada.horario}
                      lotacaoId={lotacao.id}
                      outraLotacaoIdVerificarDisponibilidade={lotacaoConvidada?.id}
                      dia={
                        agendarCuidadoValue.consultaCompartilhada?.data
                          ? parseISO(agendarCuidadoValue.consultaCompartilhada.data)
                          : undefined
                      }
                      disabled={!agendarCuidadoValue.consultaCompartilhada?.data}
                      labels={{ inicial: 'Horário do agendamento' }}
                      required
                    />
                  </Cell>

                  {PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && GARANTIA_ACESSO_ENABLED && (
                    <Cell size={6}>
                      <EnviarGarantiaAcessoCuidadoCompartilhadoField
                        name={path.garantiaAcesso}
                        pathCondutaGroup={path.condutaGroup}
                        tipoAgendamento={CondutaCuidadoCompartilhadoEnum.AGENDADO_INTERCONSULTA_COM_CIDADAO}
                        cidadaoAceitaAtendTic={cuidadoCompartilhado.cidadaoAceitaAtendTic}
                      />
                    </Cell>
                  )}

                  {(videochamadasEnabled || loadingConfiguracoesVideochamada) && (
                    <Cell size={6}>
                      <InserirVideochamadaAgendamentoSection
                        videochamadaUuid={videochamadaUuid}
                        onVideochamadaUuidChange={setVideochamadaUuid}
                        onRemoverVideochamadaClick={() => setVideochamadaUuid(null)}
                      />
                    </Cell>
                  )}

                  <Cell size={12}>
                    <VFlow vSpacing={0}>
                      <HFlow alignItems='center' hSpacing={0.25}>
                        <Text fontSize={0.875} fontWeight='bold'>
                          Dados do profissional convidado
                        </Text>
                        <InfoIcon
                          icon='infoCircleFilled'
                          text='Os dados de contato adicionados valerão apenas para o agendamento, sem edição no cadastro do profissional.'
                        />
                      </HFlow>
                      <Text>
                        {lotacaoConvidada.profissional.nome} | {lotacaoConvidada.cbo.nome.capitalize()} |{' '}
                        {lotacaoConvidada.equipe?.nome}
                      </Text>
                    </VFlow>
                  </Cell>

                  <Cell size={12}>
                    <InfoLabel title='E-mail'>{lotacaoConvidada?.profissional.email ?? '-'}</InfoLabel>
                  </Cell>

                  <Cell size={12}>
                    <VFlow vSpacing={0.5}>
                      <Text fontSize={0.875} fontWeight='bold'>
                        Dados do cidadão
                      </Text>

                      <VFlow vSpacing={1}>
                        {!cuidadoCompartilhado.cidadaoAceitaAtendTic && (
                          <Alert type='info'>
                            O cidadão não concordou com agendamento da consulta com o especialista mediada por
                            tecnologia durante o atendimento em que houve a solicitação de cuidado compartilhado.
                          </Alert>
                        )}

                        <CheckboxField
                          name={path.agendamento.consultaCompartilhada.isCidadaoPresente}
                          label={<Text fontWeight='bold'>Cidadão estará presente na consulta compartilhada</Text>}
                          style={styles.checkbox}
                        />
                      </VFlow>
                    </VFlow>
                  </Cell>

                  <Cell size={6} lg={cidadaoHasEmail ? 3 : 6}>
                    {cidadaoHasEmail ? (
                      <InfoLabel title='E-mail do cidadão'>{cuidadoCompartilhado.cidadao.email}</InfoLabel>
                    ) : (
                      <TextField
                        lowercase
                        name={path.agendamento.consultaCompartilhada.cidadaoParticipante.contato.email}
                        label='E-mail do cidadão'
                        maxLength={50}
                        disabled={!isCheckedCidadaoPresenteConsultaCompartilhada}
                      />
                    )}
                  </Cell>

                  <Cell size={6}>
                    {cidadaoHasTelefone ? (
                      <InfoLabel title='Telefone celular do cidadão'>
                        <Telefone value={cuidadoCompartilhado.cidadao.telefoneCelular} />
                      </InfoLabel>
                    ) : (
                      <TelefoneField
                        name={path.agendamento.consultaCompartilhada.cidadaoParticipante.contato.telefoneCelular}
                        label='Telefone celular do cidadão'
                        disabled={!isCheckedCidadaoPresenteConsultaCompartilhada}
                      />
                    )}
                  </Cell>

                  {!cuidadoCompartilhado.cidadaoAceitaAtendTic && (
                    // TODO Supernova: #19252 - exportar frase
                    <Cell size={12}>
                      <IconLabel
                        icon='exclamationTriangleFilled'
                        color={theme.pallete.status.alert.main}
                        text='O cidadão não concordou com agendamento da consulta com o especialista mediada por
                  tecnologia durante o atendimento em que houve a solicitação de cuidado compartilhado.'
                      />
                    </Cell>
                  )}

                  <Cell size={12}>
                    <TextAreaField
                      label='Observações'
                      name={path.agendamento.consultaCompartilhada.observacoes}
                      placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
                      maxLength={200}
                      style={styles.textArea}
                    />
                  </Cell>
                </Grid>
              </VFlow>
            )}
          </VFlow>
        ) : (
          <EnviarGarantiaAcessoCuidadoCompartilhadoPanel
            path={path}
            garantiaAcessoInput={garantiaAcessoValue}
            cuidadoCompartilhado={cuidadoCompartilhado}
          />
        )}
      </VFlow>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}

const styles = {
  checkbox: css`
    margin-left: -0.25rem;
  `,
  textArea: css`
    resize: vertical;
  `,
  textMotivo: css`
    word-break: break-all;
  `,
}
