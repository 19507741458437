import axios, { AxiosPromise } from 'axios'
import { Maybe } from 'graphql/types.generated'

import { ImpressaoAtendimentoInput } from './ImpressaoAtendimentoIndividual'

export interface ImpressaoRegistrosHistoricoInput {
  registros: ImpressaoHistoricoInput[]
}

export interface ImpressaoHistoricoInput extends Maybe<ImpressaoAtendimentoInput> {
  cuidadoCompartilhadoEvolId?: ID
}

export class ImpressaoRegistrosHistorico {
  imprimirRegistrosHistorico(input: ImpressaoRegistrosHistoricoInput): AxiosPromise<Blob> {
    return axios.post('/api/atendimento/imprimirRegistrosHistorico', input, { responseType: 'blob' })
  }
}
