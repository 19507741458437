/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { SelectMenuItem, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { SelectDownshiftMenuProps } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { SelectField } from 'components/form/final-form'
import { HLabel } from 'components/HLabel'
import { CidSelectFieldDocument } from 'graphql/hooks.generated'
import { Cid10QueryInput, CidSelectFieldQuery, CidSelectFieldQueryVariables } from 'graphql/types.generated'
import { Fragment, useMemo } from 'react'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type CidSelectFieldModel = CidSelectFieldQuery['cids']['content'][0]

export interface CidSelectFieldProps
  extends AsyncSelectFieldProps<CidSelectFieldModel>,
    Pick<Cid10QueryInput, 'sexo' | 'parteBucal' | 'idadeCidadaoEmAnos'> {
  ciapRelacionada?: ID
  renderGrupoCiapRelacionada?: boolean
}

const ID_GRUPO_CIDS_RELACIONADAS = 'grupoCidsRelacionadas'
const ID_GRUPO_OUTRAS_CIDS = 'grupoOutrasCids'

const GRUPO_CIDS_RELACIONADAS_HEADER_ITEM: CidSelectFieldModel = {
  id: ID_GRUPO_CIDS_RELACIONADAS,
  ciapsRelacionadas: [],
  codigo: '',
  nome: '',
}

const GRUPO_OUTRAS_CIDS_HEADER_ITEM: CidSelectFieldModel = {
  id: ID_GRUPO_OUTRAS_CIDS,
  ciapsRelacionadas: [],
  codigo: '',
  nome: '',
}

export function CidSelectField(props: CidSelectFieldProps) {
  const { sexo, ciapRelacionada, renderGrupoCiapRelacionada = true, parteBucal, idadeCidadaoEmAnos, ...rest } = props

  const {
    selectProps: { items, ...asyncPropsRest },
  } = useAsyncQuerySelect<CidSelectFieldModel, CidSelectFieldQuery, CidSelectFieldQueryVariables>({
    query: CidSelectFieldDocument,
    extractItems: (data) => data?.cids?.content,
    variables: (inputQuery: string): CidSelectFieldQueryVariables => ({
      input: {
        query: inputQuery,
        sexo,
        ciapRelacionada,
        parteBucal,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
        idadeCidadaoEmAnos,
      },
      fetchCiapsRelacionadas: !!ciapRelacionada,
    }),
  })

  const selectItems = useMemo<{
    hasCidsRelacionadas: boolean
    hasOutrasCids: boolean
    items: CidSelectFieldModel[]
  }>(() => {
    items?.sort((a, b) => {
      return a.nome.localeCompare(b.nome)
    })

    const cidsRelacionadasComCiap: CidSelectFieldModel[] = renderGrupoCiapRelacionada
      ? [GRUPO_CIDS_RELACIONADAS_HEADER_ITEM]
      : []

    const outrasCid: CidSelectFieldModel[] = renderGrupoCiapRelacionada ? [GRUPO_OUTRAS_CIDS_HEADER_ITEM] : []

    items?.forEach((item) => {
      item.ciapsRelacionadas?.map((value) => value.id).includes(ciapRelacionada)
        ? cidsRelacionadasComCiap.push(item)
        : outrasCid.push(item)
    })

    return {
      hasCidsRelacionadas: cidsRelacionadasComCiap.length > 1,
      hasOutrasCids: outrasCid.length > 1,
      items: cidsRelacionadasComCiap.concat(outrasCid),
    }
  }, [items, renderGrupoCiapRelacionada, ciapRelacionada])

  const itemToString = (item: CidSelectFieldModel) => item && `${item.nome} - ${item.codigo}`

  const renderItem = (item: CidSelectFieldModel) => {
    return (
      <Fragment>
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>{item.nome}</Text>
          <HLabel title='Código '>{item.codigo}</HLabel>
        </VFlow>
      </Fragment>
    )
  }

  const Item = (
    itemProps: SelectDownshiftMenuProps<CidSelectFieldModel> & { item: CidSelectFieldModel; index: number }
  ) => {
    const {
      index,
      downshift: { getItemProps, highlightedIndex },
      item,
      renderItem,
    } = itemProps

    const renderGrupoCidsRelacionadas = itemProps.item?.id === ID_GRUPO_CIDS_RELACIONADAS
    const renderGrupoOutrasCid = itemProps.item?.id === ID_GRUPO_OUTRAS_CIDS

    return (
      <Fragment>
        {renderGrupoCidsRelacionadas || renderGrupoOutrasCid ? (
          <RenderGrupoHeader
            renderGrupoOutrasCid={renderGrupoOutrasCid}
            hasCiapSelecionada={ciapRelacionada}
            hasCidsRelacionadas={selectItems.hasCidsRelacionadas}
            hasOutrasCids={selectItems.hasOutrasCids}
            itemProps={getItemProps({ item, disabled: true })}
          />
        ) : (
          <SelectMenuItem selected={highlightedIndex === index} {...getItemProps({ item })}>
            {renderItem(item)}
          </SelectMenuItem>
        )}
      </Fragment>
    )
  }

  return (
    <SelectField<CidSelectFieldModel>
      itemToString={itemToString}
      items={selectItems.items}
      renderItem={renderItem}
      components={{
        Item,
      }}
      {...asyncPropsRest}
      {...rest}
    />
  )
}

interface GrupoHeaderProps {
  hasCiapSelecionada: boolean
  hasCidsRelacionadas: boolean
  hasOutrasCids: boolean
  renderGrupoOutrasCid: boolean
  itemProps: any
}

function RenderGrupoHeader(props: GrupoHeaderProps) {
  const { hasCiapSelecionada, renderGrupoOutrasCid, hasCidsRelacionadas, hasOutrasCids, itemProps } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const emptyGroupMessage = renderGrupoOutrasCid
    ? !hasOutrasCids && 'Nenhuma outra CID10.'
    : !hasCiapSelecionada
    ? 'Nenhuma CIAP2 selecionada.'
    : !hasCidsRelacionadas && 'Nenhuma CID10 relacionada.'

  return (
    <Fragment>
      <SelectMenuItem style={styles.headerItem} {...itemProps}>
        <Text fontWeight='bold' style={styles.headerItemText}>
          {!renderGrupoOutrasCid ? 'CID10 relacionadas com a CIAP2 selecionada' : 'Outras CID10'}
        </Text>
      </SelectMenuItem>
      {emptyGroupMessage && (
        <SelectMenuItem style={styles.disabledItem} {...itemProps}>
          <Text>{emptyGroupMessage}</Text>
        </SelectMenuItem>
      )}
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  headerItem: css`
    pointer-events: none;
    cursor: not-allowed;
    background-color: ${theme.pallete.primary.c90};
  `,
  headerItemText: css`
    color: ${theme.pallete.primary.c40};
  `,
  disabledItem: css`
    pointer-events: none;
    cursor: not-allowed;
  `,
})
