import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableLoadingRow,
  TablePlaceholderRow,
  TableRow,
  Text,
  Tooltip,
} from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { TableBox } from 'components/table'
import { useUnidadesSaudeByTipoVinculacaoServicoQuery } from 'graphql/hooks.generated'
import { UnidadeSaude, VinculacaoServicoTipoEstabelecimentoEnum } from 'graphql/types.generated'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { emptyArray } from 'util/array'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'

import { EstabelecimentosReceptoresTableFieldFilter } from './EstabelecimentosReceptoresTableFieldFilter'
import { EstabelecimentosReceptoresTableFieldFilterModel } from './model-vincularEstabelecimentoReferencia'

interface EstabelecimentosReceptoresTableFieldProps {
  name: MetaPath<UnidadeSaude[]>
  disabled: boolean
  estabelecimentosSelecionados: ReadonlyArray<UnidadeSaude>
  formChangeValue: (name: string, value?: UnidadeSaude[]) => void
}

export function EstabelecimentosReceptoresTableField(props: EstabelecimentosReceptoresTableFieldProps) {
  const { name, disabled, estabelecimentosSelecionados, formChangeValue } = props

  const [filter, setFilter] = useState<EstabelecimentosReceptoresTableFieldFilterModel>({
    query: null,
    mostrarSomenteSemVinculo: false,
  })

  const { data, loading } = useUnidadesSaudeByTipoVinculacaoServicoQuery({
    variables: {
      input: {
        query: filter.query,
        tipoEstabelecimento: VinculacaoServicoTipoEstabelecimentoEnum.VINCULADOS,
      },
    },
  })

  const { unidadesSaudeByTipoVinculacaoServico } = data ?? {}

  const filterSemVinculos = useCallback(() => {
    const content = unidadesSaudeByTipoVinculacaoServico as UnidadeSaude[]
    if (filter.mostrarSomenteSemVinculo) {
      const estabelecimentosSelecionadosId = estabelecimentosSelecionados.map(({ id }) => id)
      return content.filter(({ id }) => !estabelecimentosSelecionadosId.includes(id))
    } else {
      return content
    }
  }, [unidadesSaudeByTipoVinculacaoServico, estabelecimentosSelecionados, filter.mostrarSomenteSemVinculo])

  const totalElements = unidadesSaudeByTipoVinculacaoServico?.length
  const rows = filterSemVinculos()
  const isNotEmpty = useMemo(() => rows?.length > 0, [rows])
  const isAllSelected = useMemo(() => {
    if (estabelecimentosSelecionados && !isUndefinedOrNull(rows)) {
      const estabelecimentosSelecionadosId = estabelecimentosSelecionados.map(({ id }) => id)

      return rows.every(({ id }) => estabelecimentosSelecionadosId.includes(id))
    } else {
      return false
    }
  }, [estabelecimentosSelecionados, rows])

  const changeValue = useCallback(
    (
      name: MetaPath<UnidadeSaude[]>,
      joinPrevValues: (prevValue: ReadonlyArray<UnidadeSaude>) => UnidadeSaude[]
    ): void => formChangeValue(name.alias, joinPrevValues(estabelecimentosSelecionados)),
    [estabelecimentosSelecionados, formChangeValue]
  )

  const addItem = useCallback(
    (value) => {
      changeValue(name, (prevValue) => [...prevValue, value])
    },
    [changeValue, name]
  )

  const removeItem = useCallback(
    (value) => {
      changeValue(name, (prevValue) => prevValue.filter((item) => item.id !== value.id))
    },
    [changeValue, name]
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, estabelecimento: UnidadeSaude) => {
      if (event.target.checked) {
        addItem(estabelecimento)
      } else {
        removeItem(estabelecimento)
      }
    },
    [addItem, removeItem]
  )

  const checkAllItems = useCallback(
    (estabelecimentos: UnidadeSaude[], isAllSelected: boolean) => {
      changeValue(name, (prevValues: UnidadeSaude[]): UnidadeSaude[] => {
        if (isAllSelected) {
          const newValuesIds = estabelecimentos ? estabelecimentos.map(({ id }) => id) : emptyArray

          return prevValues.filter((valueItem) => !newValuesIds.includes(valueItem.id))
        } else {
          const prevValuesIds = prevValues ? prevValues.map(({ id }) => id) : []
          const newValues = estabelecimentos.filter(({ id }) => !prevValuesIds.includes(id))

          return [...prevValues, ...newValues]
        }
      })
    },
    [changeValue, name]
  )

  return (
    <TableBox header={<EstabelecimentosReceptoresTableFieldFilter initialValues={filter} onChange={setFilter} />}>
      <Table hovered>
        <Tooltip text={disabled && 'Selecione um estabelecimento referência'}>
          <TableBody>
            {loading ? (
              <TableLoadingRow />
            ) : isNotEmpty ? (
              <>
                <TableRow style={{ backgroundColor: gray.c90 }}>
                  <TableCell>
                    <Checkbox
                      disabled={disabled}
                      label={<Text fontWeight='bold'>Selecionar todas as unidades básicas</Text>}
                      checked={isAllSelected}
                      onChange={() => checkAllItems(rows, isAllSelected)}
                    />
                  </TableCell>
                </TableRow>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Checkbox
                        key={row.id}
                        disabled={disabled}
                        label={
                          <Text fontWeight='bold'>
                            {row.nome} - {row.cnes}
                          </Text>
                        }
                        checked={estabelecimentosSelecionados
                          .map((estabelecimento) => estabelecimento.id)
                          .includes(row.id)}
                        onChange={(event) => handleChange(event, row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TablePlaceholderRow />
            )}
          </TableBody>
        </Tooltip>
      </Table>
      <TableFooter totalElements={totalElements} style={{ borderTopWidth: 0 }} />
    </TableBox>
  )
}
