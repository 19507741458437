import { HFlow, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'

export function NewsModalBodyLinks() {
  const {
    VISUALIZACAO_TERRITORIO_ENABLED,
    CUIDADO_COMPARTILHADO_ENABLED,
    HISTORICO_CUIDADO_COMPARTILHADO_ENABLED,
    PILOTO_VIDEOCHAMADA_2_ENABLED,
    PILOTO_VIDEOCHAMADA_3_ENABLED,
    PILOTO_ATESTADO_DIGITAL_ENABLED,
  } = useFlags()
  return (
    <HFlow hSpacing={2}>
      <VFlow vSpacing={0.2}>
        {PILOTO_VIDEOCHAMADA_2_ENABLED && PILOTO_VIDEOCHAMADA_3_ENABLED && (
          <Text component='li'>Agendamento entre profissionais</Text>
        )}
        {VISUALIZACAO_TERRITORIO_ENABLED && <Text component='li'>Acompanhamento do Território</Text>}
        {PILOTO_ATESTADO_DIGITAL_ENABLED && <Text component='li'>Atestado Digital</Text>}
        <Text component='li'>Busca ativa de vacinação</Text>
        {CUIDADO_COMPARTILHADO_ENABLED && <Text component='li'>Cuidado Compartilhado</Text>}
        <Text component='li'>e-SUS APS Ampliado</Text>
        <Text component='li'>Facilidades no agendamento de consultas de pré-natal</Text>
        <Text component='li'>Gerenciamento de cookies</Text>
      </VFlow>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Garantia do acesso</Text>
        {HISTORICO_CUIDADO_COMPARTILHADO_ENABLED && <Text component='li'>Histórico do Cuidado Compartilhado</Text>}
        <Text component='li'>Prescrição de medicamentos</Text>
        <Text component='li'>Prescrição digital</Text>
        <Text component='li'>Relatório gerencial de cuidado compartilhado</Text>
        <Text component='li'>RNDS - Envio de registros</Text>
        <Text component='li'>Videochamadas</Text>
        <Text component='li'>Unificação de cadastros</Text>
        <Text component='li'>Vínculo entre equipes do PEC</Text>
      </VFlow>
    </HFlow>
  )
}
