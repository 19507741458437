/** @jsx jsx */
import { useApolloClient } from '@apollo/react-hooks'
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { DefaultFormFooter, ErrorField, Form, FormPrompt } from 'components/form'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import createDecorator from 'final-form-calculate'
import { VinculacaoServicoTipoEstabelecimentoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { FormRenderProps } from 'react-final-form'
import { emptyArray } from 'util/array'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

import { vincularEstabelecimentoCalculator } from './calculator-vincularEstabelecimentoReferencia'
import { UnidadeSaudeByTipoVinculacaoServicoSelectField } from './components/UnidadeSaudeByTipoVinculacaoServicoSelectField'
import { EstabelecimentosReceptoresTableField } from './EstabelecimentosReceptoresTableField'
import { VincularEstabelecimentoReferenciaFormModel } from './model-vincularEstabelecimentoReferencia'

const meta = metaPath<VincularEstabelecimentoReferenciaFormModel>()

const validate = createValidator<VincularEstabelecimentoReferenciaFormModel>({
  referencia: [required],
  vinculacoes: [required],
})

interface VincularEstabelecimentoReferenciaFormProps {
  initialValues: VincularEstabelecimentoReferenciaFormModel
  isEdit: boolean
  onSubmit(values: VincularEstabelecimentoReferenciaFormModel): any
  onSubmitSucceeded?(): any
  onCancel?(): any
}

export function VincularEstabelecimentoReferenciaForm(props: VincularEstabelecimentoReferenciaFormProps) {
  const { initialValues, isEdit, onSubmit, onSubmitSucceeded, onCancel } = props
  const theme = useTheme()
  const styles = createStyles(theme)
  const apollo = useApolloClient()

  const decorator = useMemo(() => [createDecorator(...vincularEstabelecimentoCalculator(meta, isEdit, apollo))], [
    apollo,
    isEdit,
  ])

  const renderForm = (formProps: FormRenderProps<VincularEstabelecimentoReferenciaFormModel>) => (
    <form onSubmit={formProps.handleSubmit} noValidate>
      <FormPrompt />
      <VFlow vSpacing={2}>
        <Grid gapVertical={0}>
          <Cell size={12} md={8} lg={7}>
            <VFlow>
              <VFlow vSpacing={0.5}>
                <Heading level={3}>
                  <RequiredLabel label='Referência' css={styles.heading} />
                </Heading>
                <UnidadeSaudeByTipoVinculacaoServicoSelectField
                  name={meta.referencia}
                  label='Unidade de saúde'
                  disabled={isEdit}
                  tipoEstabelecimento={VinculacaoServicoTipoEstabelecimentoEnum.REFERENCIA}
                />
              </VFlow>

              <div css={styles.lineRow}>
                <div css={styles.line} />
                <VFlow vSpacing={0} css={styles.lineVFlow}>
                  <Text css={styles.lineText}>Vínculo</Text>
                  <Icon icon='arrowDown' size={2.5} color={gray.c40} />
                </VFlow>
                <div css={styles.line} />
              </div>
            </VFlow>
          </Cell>

          <Cell size={12}>
            <VFlow vSpacing={0.5}>
              <Heading level={3}>
                <RequiredLabel label='Receptora' css={styles.heading} />
              </Heading>
              <ErrorField name={meta.vinculacoes.absolutePath()} />

              <EstabelecimentosReceptoresTableField
                name={meta.vinculacoes}
                disabled={isUndefinedOrNull(formProps.values.referencia?.id)}
                estabelecimentosSelecionados={formProps.values.vinculacoes ?? emptyArray}
                formChangeValue={formProps.form.change}
              />
            </VFlow>
          </Cell>
        </Grid>

        <DefaultFormFooter handleSubmit={formProps.handleSubmit} onCancel={onCancel} />
      </VFlow>
    </form>
  )

  return (
    <Form<VincularEstabelecimentoReferenciaFormModel>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={onSubmit}
      validate={validate}
      decorators={decorator}
      onSubmitSucceeded={onSubmitSucceeded}
    />
  )
}

const createStyles = (theme: Theme) => ({
  heading: css`
    font-size: 1rem;
  `,
  lineRow: css`
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    width: 100%;
  `,
  line: css`
    width: inherit;
    height: 1px;
    background-color: ${theme.pallete.gray.c80};
  `,
  lineText: css`
    font-style: italic;
    font-size: 0.875rem;
    color: ${theme.pallete.gray.c20};
  `,
  lineVFlow: css`
    text-align: center;
  `,
})
