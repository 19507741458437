import { Table, TableBody, TableFooter, TableLoadingRow, TablePlaceholderRow } from 'bold-ui'
import { TableBox, usePagedTableProps } from 'components/table'
import { useEquipeEMultiVinculacoesTableQuery } from 'graphql/hooks.generated'
import { EquipeEMultiVinculacoesTableQuery, TodasVinculacoesEquipesInput } from 'graphql/types.generated'
import React, { useMemo, useState } from 'react'

import { EquipeEMultiVinculacoesTableHeader } from './EquipeEMultiVinculacoesTableHeader'
import { EquipeEMultiVinculacoesTableRow } from './EquipeEMultiVinculacoesTableRow'

export type EquipeEMultiVinculacoesItem = EquipeEMultiVinculacoesTableQuery['todasVinculacoesEquipe']['content'][0]

export default function EquipeEMultiVinculacoesTable() {
  const [filter, setFilter] = useState<TodasVinculacoesEquipesInput>({ pageParams: { size: 10 } })
  const [expandedRow, setExpandedRow] = useState<ID>()

  const { data, loading } = useEquipeEMultiVinculacoesTableQuery({ variables: { input: filter } })

  const tableProps = usePagedTableProps({
    loading,
    result: data?.todasVinculacoesEquipe,
    onChange: setFilter,
  })
  const { rows, loading: isLoading } = tableProps

  const handleClick = (row: EquipeEMultiVinculacoesItem) => () => {
    setExpandedRow(expandedRow === row.equipeEmulti.id ? null : row.equipeEmulti.id)
  }

  const isNotEmpty = useMemo(() => rows?.length > 0, [rows])

  const tableHeader = <EquipeEMultiVinculacoesTableHeader initialValues={filter} onChange={setFilter} />

  return (
    <TableBox header={tableHeader}>
      <Table hovered>
        <TableBody>
          {isLoading ? (
            <TableLoadingRow />
          ) : isNotEmpty ? (
            rows.map((row) => (
              <EquipeEMultiVinculacoesTableRow
                key={row.equipeEmulti.id}
                equipeEMultiVinculacao={row}
                expandedRow={expandedRow}
                handleClick={handleClick}
              />
            ))
          ) : (
            <TablePlaceholderRow />
          )}
        </TableBody>
      </Table>
      <TableFooter
        {...tableProps}
        style={{ borderTopWidth: 0 }}
        pageSize={tableProps.size}
        totalElements={data?.todasVinculacoesEquipe?.pageInfo?.totalElements ?? 0}
        sizeOptions={[5, 10, 15, 25]}
      />
    </TableBox>
  )
}
