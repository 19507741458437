import { CidadaoAtendimentoPorCnsSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CidadaoAtendimentoPorCnsSelectFieldQuery,
  CidadaoAtendimentoPorCnsSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { CNS_SIZE_REGEX } from 'util/validation'

import { CidadaoAtendimentoSelectFieldModel } from '../CidadaoAtendimentoSelectField'
import { CidadaoAtendimentoSelectFieldCommon } from '../CidadaoAtendimentoSelectField/CidadaoAtendimentoSelectFieldCommon'
import { useAsyncQuerySelect } from '../useAsyncQuerySelect'
import { CidadaoAtendimentoPorPropriedadeEmptyItem } from './CidadaoAtendimentoPorPropriedadeEmptyItem'
import { CidadaoAtendimentoPorPropriedadeLoadingItem } from './CidadaoAtendimentoPorPropriedadeLoadingItem'
import { CidadaoAtendimentoPorPropriedadeSelectFieldProps } from './CidadaoAtendimentoPorPropriedadeSelectField'
import { commonCidadaoAtendimentoPorPropriedadeSelectFieldInput } from './util-cidadaoAtendimentoPorPropriedadeSelectField'

interface CidadaoAtendimentoPorCnsSelectFieldProps extends CidadaoAtendimentoPorPropriedadeSelectFieldProps {
  addCidadaoCallbackUrl: string
}

export function CidadaoAtendimentoPorCnsSelectField(props: CidadaoAtendimentoPorCnsSelectFieldProps) {
  const { name, addCidadaoCallbackUrl } = props

  const { skipping, selectProps } = useAsyncQuerySelect<
    CidadaoAtendimentoSelectFieldModel,
    CidadaoAtendimentoPorCnsSelectFieldQuery,
    CidadaoAtendimentoPorCnsSelectFieldQueryVariables
  >({
    query: CidadaoAtendimentoPorCnsSelectFieldDocument,
    extractItems: (data) => data && data.cidadaosComboByCns,
    variables: (inputString) => ({
      input: {
        ...commonCidadaoAtendimentoPorPropriedadeSelectFieldInput,
        query: inputString,
      },
    }),
    skip: (inputString) => !CNS_SIZE_REGEX.test(inputString?.trim()),
    debounceTime: 500,
  })

  return (
    <CidadaoAtendimentoSelectFieldCommon
      name={name}
      addCidadaoCallbackUrl={addCidadaoCallbackUrl}
      placeholder='Digite o CNS completo do cidadão'
      components={{
        EmptyItem: () => (
          <CidadaoAtendimentoPorPropriedadeEmptyItem
            skipping={skipping}
            skippingText='Informe um CNS válido para realizar a busca'
          />
        ),
        LoadingItem: () => <CidadaoAtendimentoPorPropriedadeLoadingItem />,
      }}
      selectProps={selectProps}
    />
  )
}
