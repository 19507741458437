/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DateRange } from 'bold-ui'
import DateRangeField from 'components/form/field/DateRangeField'
import MonthRangeField from 'components/form/field/MonthRangeField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { useServerTime } from 'hooks/useServerTime'
import { lastMonthAsReferenceMonth } from 'util/date/converter'
import { Meta } from 'util/metaPath'

import { RelatoriosGerenciaisUnidadeMedidaTempoEnum } from '../../common/model-relatorioGerencial'

interface RangeFieldProps {
  unidadePeriodoSelecionada: RelatoriosGerenciaisUnidadeMedidaTempoEnum
  name: Meta<DateRange>
  disabled: boolean
}

export function RangeField(props: RangeFieldProps) {
  const { name, unidadePeriodoSelecionada, disabled } = props

  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  const { input } = usePecField<DateRange>({
    name,
    subscription: { value: true },
  })

  const periodo = input.value as DateRange

  if (unidadePeriodoSelecionada === RelatoriosGerenciaisUnidadeMedidaTempoEnum.DIA) {
    return (
      <DateRangeField name={name} value={periodo} disabled={disabled} maxDate={now} style={styles.field} clearable />
    )
  } else if (unidadePeriodoSelecionada === RelatoriosGerenciaisUnidadeMedidaTempoEnum.SEMANA) {
    return (
      <DateRangeField
        name={name}
        value={periodo}
        disabled={disabled}
        maxDate={now}
        style={styles.field}
        clearable
        onlyWeeks
      />
    )
  } else {
    return (
      <MonthRangeField
        name={name}
        disabled={disabled}
        maxMonth={lastMonthAsReferenceMonth(now)}
        style={styles.field}
        clearable
      />
    )
  }
}

const styles = {
  field: css`
    max-width: 25rem;
  `,
}
