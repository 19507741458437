/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, DateRange, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { ErrorField, Form, FormRenderProps } from 'components/form'
import {
  CiapCidSelectField,
  CiapCidSelectModel,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import {
  GrupoCondicaoSelectField,
  GrupoCondicaoSelectModel,
} from 'components/form/field/select/GrupoCondicaoSelectField'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import {
  RelatoriosGerenciaisOpcoesPeriodoEnum,
  RelatoriosGerenciaisUnidadeMedidaTempoEnum,
} from '../../common/model-relatorioGerencial'
import { createRelatoriosGerenciaisPeriodoFormCalculator } from './calculator-relatorioGerencial'
import {
  convertCiapCidToBIFiltroCiapCid,
  convertGrupoCondicoesPrioritariosToCiapsCids,
} from './converter-relatorioGerencial'
import { PeriodoRadioField } from './RelatorioGerencialPeriodoRadioField'
import { UnidadePeriodoRadioField } from './RelatorioGerencialUnidadePeriodoRadioField'
import { relatoriosGerenciaisPeriodoValidator } from './validator-relatorioGerencial'

export interface RelatoriosGerenciaisPeriodoFormModel {
  unidade: RelatoriosGerenciaisUnidadeMedidaTempoEnum
  opcaoSelecionada: RelatoriosGerenciaisOpcoesPeriodoEnum
  periodo?: DateRange
  ciapsCids: CiapCidSelectModel[]
  gruposCondicoesPrioritarios: GrupoCondicaoSelectModel[]
}

const meta = metaPath<RelatoriosGerenciaisPeriodoFormModel>()

const TOOLTIP_MESSAGE =
  'Selecione o tipo de período desejado: Mensal, Semanal ou Personalizado (dias). Depois, selecione o período com as opções predefinidas ou marcando manualmente nos campos de início e fim.'

export interface RelatoriosGerenciaisPeriodoFormProps {
  loading: boolean
  isRelatorioAtendimentos?: boolean
  onSubmit: (periodo: DateRange, unidade: string, ciapsCids: Set<string>) => void
  setIsFormDirty: (isDirty: boolean) => void
}

export function RelatoriosGerenciaisPeriodoForm(props: RelatoriosGerenciaisPeriodoFormProps) {
  const { loading, isRelatorioAtendimentos = false, onSubmit, setIsFormDirty } = props

  const initialValues = useMemo<RelatoriosGerenciaisPeriodoFormModel>(
    () => ({
      opcaoSelecionada: RelatoriosGerenciaisOpcoesPeriodoEnum.PRIMEIRA,
      unidade: RelatoriosGerenciaisUnidadeMedidaTempoEnum.MES,
      ciapsCids: [],
      gruposCondicoesPrioritarios: [],
      periodo: null,
    }),
    []
  )

  const decorators = useMemo(() => [createDecorator(createRelatoriosGerenciaisPeriodoFormCalculator(meta))], [])

  const theme = useTheme()
  const { tooltip } = createStyles(theme)

  const handleSubmit = ({
    ciapsCids,
    unidade,
    periodo,
    gruposCondicoesPrioritarios,
  }: RelatoriosGerenciaisPeriodoFormModel) => {
    const ciapsCidsGrupoCondicoes = convertGrupoCondicoesPrioritariosToCiapsCids(gruposCondicoesPrioritarios)
    const ciapsCidsSet = convertCiapCidToBIFiltroCiapCid(ciapsCids.concat(ciapsCidsGrupoCondicoes))
    onSubmit(periodo, unidade, ciapsCidsSet)
  }

  const renderForm = ({
    handleSubmit,
    dirtySinceLastSubmit,
  }: FormRenderProps<RelatoriosGerenciaisPeriodoFormModel>) => {
    setIsFormDirty(dirtySinceLastSubmit)

    return (
      <VFlow>
        <HFlow hSpacing={0.3}>
          <Text variant='h5'>Período</Text>
          <Tooltip text={TOOLTIP_MESSAGE} style={tooltip}>
            <Icon icon='infoCircleFilled' size={1} />
          </Tooltip>
        </HFlow>
        <ErrorField name={FORM_ERROR} />
        <Grid wrap alignItems='center'>
          <Cell size={12}>
            <UnidadePeriodoRadioField name={meta.unidade} disabled={loading} />
          </Cell>
          <Cell size={12}>
            <PeriodoRadioField namePeriodoForm={meta} disabled={loading} />
          </Cell>
        </Grid>
        {isRelatorioAtendimentos && (
          <Grid alignItems='stretch' direction='row'>
            <Cell size={6}>
              <CiapCidSelectField
                label='CIAP2 e CID10'
                placeholder='Selecione outros CIAP2 e CID10'
                name={meta.ciapsCids}
                disabled={loading}
                multiple
              />
            </Cell>
            <Cell size={6}>
              <GrupoCondicaoSelectField
                label='Grupos de condições prioritários'
                placeholder='Selecione grupos de condições prioritários'
                name={meta.gruposCondicoesPrioritarios}
                disabled={loading}
                multiple
              />
            </Cell>
          </Grid>
        )}
        <Button kind='primary' size='small' type='submit' onClick={handleSubmit} loading={loading} disabled={loading}>
          Consultar
        </Button>
      </VFlow>
    )
  }

  return (
    <Form<RelatoriosGerenciaisPeriodoFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      validate={relatoriosGerenciaisPeriodoValidator}
      initialValues={initialValues}
      decorators={decorators}
    />
  )
}

const createStyles = (theme: Theme) => ({
  tooltip: css`
    background: ${theme.pallete.gray.c20};
  `,
})
