import { FORM_ERROR } from 'final-form'
import {
  createValidator,
  maxWeeksPeriod,
  maxYearsPeriod,
  periodBeforeEqualToday,
  required,
  requiredPeriod,
} from 'util/validation'
import { isValid } from 'util/validation/Util'

import {
  RELATORIOS_GERENCIAIS_PERIOD_MAX_WEEKS,
  RELATORIOS_GERENCIAIS_PERIOD_MAX_YEARS,
  RelatoriosGerenciaisUnidadeMedidaTempoEnum,
} from '../../common/model-relatorioGerencial'
import { RelatoriosGerenciaisPeriodoFormModel } from './RelatorioGerencialPeriodoForm'

export const relatoriosGerenciaisPeriodoValidator = createValidator<RelatoriosGerenciaisPeriodoFormModel>(
  {
    periodo: [requiredPeriod, periodBeforeEqualToday],
    opcaoSelecionada: [required],
    unidade: [required],
  },
  ({ unidade, periodo }, errors) => {
    if (isValid(errors.periodo)) {
      if (unidade === RelatoriosGerenciaisUnidadeMedidaTempoEnum.SEMANA) {
        errors[FORM_ERROR] = maxWeeksPeriod(periodo, RELATORIOS_GERENCIAIS_PERIOD_MAX_WEEKS)
      } else {
        errors[FORM_ERROR] = maxYearsPeriod(periodo, RELATORIOS_GERENCIAIS_PERIOD_MAX_YEARS)
      }
    }
    return errors
  }
)
