import { ImpressaoHistoricoInput } from 'api/ImpressaoRegistrosHistorico'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useMap } from 'hooks/useMap'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'

import { downloadRegistrosHistorico } from '../../components/downloadRegistrosHistorico'
import { HistoricoAtendimentoModel } from '../model/model-historico'
import { isHistoricoPrintEnable } from '../utils/historicoUtils'

interface HistoricoPrintOptions {
  page: number
  enabledToPrintItemCount: number
}

const isEqual = (atendimento: HistoricoAtendimentoModel, impressao: ImpressaoHistoricoInput) =>
  impressao.atendimentoProfissionalId === atendimento.idAtendRecente &&
  impressao.historicoCabecalhoId === atendimento.idAtendProcessado &&
  impressao.cuidadoCompartilhadoEvolId === atendimento.cuidadoCompartilhadoEvolucao?.id

const convertToImpressao = (atendimento: HistoricoAtendimentoModel): ImpressaoHistoricoInput => ({
  atendimentoProfissionalId: atendimento.idAtendRecente,
  historicoCabecalhoId: atendimento.idAtendProcessado,
  cuidadoCompartilhadoEvolId: atendimento.cuidadoCompartilhadoEvolucao?.id,
})

const createPage = (current: ImpressaoHistoricoInput[], value: ImpressaoHistoricoInput[]) =>
  isEmpty(current) ? value : [...current, ...value]

export const useHistoricoPrint = ({ page, enabledToPrintItemCount }: HistoricoPrintOptions) => {
  const [selectionPages, { set: setSelectionPage, remove: removeSelectionPage, reset: clearSelections }] = useMap<
    Record<number, ImpressaoHistoricoInput[]>
  >()
  const handleRejection = useErrorHandler()

  return useMemo(() => {
    const isItemSelected = (atendimento: HistoricoAtendimentoModel) =>
      !!selectionPages[page]?.some((impressao) => isEqual(atendimento, impressao))

    const isAllPageItemsSelected =
      !isEmpty(selectionPages[page]) && selectionPages[page].length === enabledToPrintItemCount

    const remove = (atendimento: HistoricoAtendimentoModel) => {
      const values = selectionPages[page]?.filter((impressao) => !isEqual(atendimento, impressao))
      setSelectionPage(page, values)
    }

    const select = (atendimento: HistoricoAtendimentoModel) => {
      const input = [convertToImpressao(atendimento)]
      const newPage = createPage(selectionPages[page], input)
      setSelectionPage(page, newPage)
    }

    const handleSelectItem = (checked: boolean, atendimento: HistoricoAtendimentoModel) =>
      checked ? select(atendimento) : remove(atendimento)

    const selectPage = (historico: HistoricoAtendimentoModel[]) => {
      const actualPage = historico
        ?.filter((atendimento) => isHistoricoPrintEnable(atendimento) && !isItemSelected(atendimento))
        ?.map((atendimento) => convertToImpressao(atendimento))

      const newPage = createPage(selectionPages[page], actualPage)
      setSelectionPage(page, newPage)
    }

    const handleSelectPage = (checked: boolean, historico: HistoricoAtendimentoModel[]) =>
      checked ? selectPage(historico) : removeSelectionPage(page)

    const print = async () => {
      const confirmed = await confirm({
        title: `Deseja continuar a impressão?`,
        body: 'Estão sendo impressos apenas os atendimentos selecionados.',
        confirmLabel: 'Sim, continuar',
        cancelLabel: 'Não, voltar',
      })()

      if (confirmed) {
        try {
          const res = await downloadRegistrosHistorico({ registros: Object.values(selectionPages).flat() })
          clearSelections()
          return res
        } catch (e) {
          return handleRejection(e as any)
        }
      }
    }

    return {
      countSelectedInPage: selectionPages[page]?.length ?? 0,
      isAllPageItemsSelected,
      isItemSelected,
      toggleSelectItem: handleSelectItem,
      toggleSelectPage: handleSelectPage,
      clearPageSelection: () => removeSelectionPage(page),
      reset: clearSelections,
      remove,
      print,
    }
  }, [
    selectionPages,
    page,
    enabledToPrintItemCount,
    clearSelections,
    setSelectionPage,
    removeSelectionPage,
    handleRejection,
  ])
}
