/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, hexToRGB, HFlow, Icon, Tag, Theme, useTheme } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { SideMenuList } from 'components/layout/SideMenu/SideMenuList'
import { ReactNode } from 'react'
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

import { NewsVersion } from '../hooks/useNewsItems'

interface NewsMenuAccordionItemProps {
  title: string
  index: number
  totalItems: number
  children?: ReactNode
  selectedVersion: string
  version: NewsVersion
  onClick: (version: NewsVersion) => void
}

export function NewsMenuAccordionItem(props: NewsMenuAccordionItemProps) {
  const { title, index, totalItems, children, selectedVersion, version, onClick } = props

  const theme = useTheme()

  const isFirst = index === 0
  const isLast = index === totalItems - 1
  const isMenuActive = selectedVersion === version

  const styles = createStyles(theme, isFirst, isLast, isMenuActive)

  const handleClick = () => {
    onClick(version)
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }

  return (
    <AccordionItem uuid={version} key={version}>
      <AccordionButton
        style={styles.accordionButton}
        wrapperStyle={styles.accordionButtonWrapper}
        onClick={handleClick}
      >
        <HFlow alignItems='center' justifyContent='space-between'>
          <HFlow hSpacing={0.5} alignItems='center' style={styles.colorHeader}>
            <Icon icon='angleDown' />
            <Heading level={4} style={styles.colorHeader}>
              {title}
            </Heading>
          </HFlow>
          {index === 0 && (
            <Tag height='1.5rem' type='info'>
              Versão atual
            </Tag>
          )}
        </HFlow>
      </AccordionButton>
      <AccordionItemPanel css={styles.panel}>
        <SideMenuList>{children}</SideMenuList>
      </AccordionItemPanel>
    </AccordionItem>
  )
}

const createStyles = (theme: Theme, isFirst: boolean, isLast: boolean, isMenuActive: boolean) => ({
  panel: css`
    border: 1px ${theme.pallete.divider} solid;
    border-top-width: 0;
  `,
  colorHeader: css`
    color: ${isMenuActive ? theme.pallete.primary.main : 'inherit'};
  `,
  accordionButtonWrapper: css`
    padding-bottom: 0;
  `,
  accordionButton: css`
    padding: 0.5rem 0.7rem;
    overflow: hidden;
    border: 1px ${theme.pallete.divider} solid;
    border-radius: ${isLast ? `0 0 ${theme.radius.input}px ${theme.radius.input}px` : '0'};
    background-color: ${theme.pallete.gray.c100};
    margin: 0;
    margin-top: ${!isFirst ? '-1px' : '0'};

    &:hover {
      border: 1px ${theme.pallete.divider} solid;
      background-color: ${hexToRGB(theme.pallete.gray.c20, 0.1)};
    }

    &:focus {
      border-radius: 2px;
      position: relative;
      z-index: 1;
    }
  `,
  dividerBottom: css`
    padding: 1rem 1rem 1.2rem 1rem;
    margin-top: 0;
  `,
})
