import { TermosRecord } from 'components/TermoModal'

import { AtorVideochamada } from './model-videochamada'

const termosVideochamada: TermosRecord<AtorVideochamada> = {
  CIDADAO: {
    titulo: 'Termo de consentimento livre e esclarecido',
    subtitulo: 'Cidadão',
    itens: [
      'Esse atendimento não resulta em cobrança de valores, uma vez que estou sendo atendido pelo Sistema Único de Saúde (SUS).',
      'A Telessaúde abrange a prestação remota de serviços relacionados a todas as profissões da área da saúde regulamentadas pelos órgãos competentes do Poder Executivo Federal e, portanto, esta videochamada, sendo admitida como prática conforme a lei nº 14.510 de 27 de dezembro de 2022.',
      'Fica informado que esta videochamada integra a telessaúde como uma Tecnologia da Informação e Comunicação (TICs) para permitir a troca de informações e opiniões à distância entre profissionais da saúde para auxílio diagnóstico, terapêutico, monitoramento ou prevenção de doenças, e que a minha adesão ao presente atendimento com essa ferramenta está sendo realizada por minha livre escolha.',
      'Em relação ao sigilo do atendimento profissional da saúde, não poderei fazer qualquer gravação do atendimento.',
      'Estou ciente que por meio da plataforma, os profissionais da saúde irão transmitir minhas informações pessoais e de saúde via videoconferência, bem como permito o registro dos dados coletados através da videoconferência em meu prontuário, para todos os fins.',
      'Caso não deseje receber o atendimento por telefone ou vídeo, estou ciente de que isso não implicará em prejuízo para o meu atendimento.',
    ],
    acceptText: 'Cidadão está ciente e concorda com a realização da videochamada',
  },
  PROFISSIONAL: {
    titulo: 'Termo de responsabilidade',
    subtitulo: 'Profissional da Saúde',
    itens: [
      'Esse atendimento não resulta em cobrança de valores, uma vez que o cidadão está sendo atendido pelo Sistema Único de Saúde (SUS).',
      'A Telessaúde abrange a prestação remota de serviços relacionados a todas as profissões da área da saúde regulamentadas pelos órgãos competentes do Poder Executivo Federal e, portanto, esta videochamada, sendo admitida como prática conforme a lei nº 14.510 de 27 de dezembro de 2022.',
      'Esta videochamada faz uso das Tecnologias da Informação e Comunicação (TICs) para permitir a troca de informações e opiniões à distância entre profissionais da saúde, com ou sem a presença do paciente, para auxílio diagnóstico, terapêutico, monitoramento ou prevenção de doenças. É posta como mais uma opção de atenção à saúde, visando à promoção desta e um melhor atendimento à população.',
      'Em relação ao sigilo do atendimento profissional da saúde, não poderei fazer qualquer gravação do atendimento.',
      'Nos serviços prestados por esta videochamada, os dados e imagens dos pacientes, serão preservados, obedecendo as normas legais pertinentes à guarda, ao manuseio, à integridade, à veracidade, à confidencialidade, à privacidade, à irrefutabilidade e à garantia do sigilo profissional das informações.',
      'O registro de ciência deste será realizado no Módulo de Auditoria para documentação e possíveis avaliações sobre a eficácia das medidas implementadas.',
      'Caso o paciente participe da videochamada, comprometo-me a coletar o consentimento livre e esclarecido do paciente para realização desta consulta, fazendo a leitura integral do termo a seguir, explicando e garantindo que caso ele não deseje receber o atendimento por telefone ou vídeo, isso não implicará em prejuízo para o seu atendimento.',
    ],
    acceptText: 'Estou ciente das informações descritas no termo',
  },
  PROFISSIONAL_EXTERNO: {
    titulo: 'Termo de responsabilidade',
    itens: [
      'Esse atendimento não resulta em cobrança de valores, uma vez que o cidadão está sendo atendido pelo Sistema Único de Saúde (SUS).',
      'A Telessaúde abrange a prestação remota de serviços relacionados a todas as profissões da área da saúde regulamentadas pelos órgãos competentes do Poder Executivo Federal e, portanto, esta videochamada, sendo admitida como prática conforme a lei nº 14.510 de 27 de dezembro de 2022.',
      'Esta videochamada faz uso das Tecnologias da Informação e Comunicação (TICs) para permitir a troca de informações e opiniões à distância entre profissionais da saúde, com ou sem a presença do paciente, para auxílio diagnóstico, terapêutico, monitoramento ou prevenção de doenças. É posta como mais uma opção de atenção à saúde, visando à promoção desta e um melhor atendimento à população.',
      'Em relação ao sigilo do atendimento profissional da saúde, não poderei fazer qualquer gravação do atendimento.',
      'Nos serviços prestados por esta videochamada, os dados e imagens dos pacientes, serão preservados, obedecendo as normas legais pertinentes à guarda, ao manuseio, à integridade, à veracidade, à confidencialidade, à privacidade, à irrefutabilidade e à garantia do sigilo profissional das informações.',
      'O registro de ciência deste será realizado no Módulo de Auditoria para documentação e possíveis avaliações sobre a eficácia das medidas implementadas.',
    ],
    acceptText: 'Estou ciente das informações descritas no termo',
  },
}

export const termosVideochamadaProfissionalPec: TermosRecord<
  AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO
> = {
  PROFISSIONAL: termosVideochamada.PROFISSIONAL,
} as TermosRecord<AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO>

export const termosVideochamadaProfissionalPecCidadaoPresente: TermosRecord<
  AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO
> = {
  PROFISSIONAL: termosVideochamada.PROFISSIONAL,
  CIDADAO: termosVideochamada.CIDADAO,
}

export const termosVideochamadaCidadaoPresente: TermosRecord<
  AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO
> = {
  CIDADAO: termosVideochamada.CIDADAO,
} as TermosRecord<AtorVideochamada.PROFISSIONAL | AtorVideochamada.CIDADAO>

export const termosVideochamadaProfissionalVisitante: TermosRecord<AtorVideochamada.PROFISSIONAL_EXTERNO> = {
  PROFISSIONAL_EXTERNO: termosVideochamada.PROFISSIONAL_EXTERNO,
}
