/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, colors, HFlow, Icon, InfoLabel, TableFooter, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { DateTime } from 'components/date'
import { Telefone } from 'components/label'
import { ButtonLink } from 'components/route'
import { TableBox, usePagedTableProps } from 'components/table'
import { useFlags } from 'config/useFlagsContext'
import { useGarantiaAcessoTableLazyQuery } from 'graphql/hooks.generated'
import { TipoEstabelecimentoEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { upperFirst } from 'lodash'
import { Fragment, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { humanizeAge } from 'util/date/humanize-age'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import { ClassificacaoPrioridadeCuidadoRecord } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { tipoAtendimentoRecord } from './components/TipoAtendimentoSelectField'
import { convertGarantiaAcessoFilterModelToInput } from './convert-garantiaAcesso'
import { GarantiaAcessoTableDropdownMenu } from './GarantiaAcessoTableDropdownMenu'
import { GarantiaAcessoTablePanel } from './GarantiaAcessoTablePanel'
import { GarantiaAcessoFilterModel, GarantiaAcessoItem } from './model-garantiaAcesso'

const PLACEHOLDER = '—'

type GarantiaAcessoTopicModel = { idUbs: number }

interface GarantiaAcessoTableProps {
  filter: GarantiaAcessoFilterModel
  onChangeFilter: (filter: GarantiaAcessoFilterModel) => void
}

export default function GarantiaAcessoTable(props: GarantiaAcessoTableProps) {
  const { filter, onChangeFilter } = props
  const match = useRouteMatch()
  const {
    acesso: { unidadeSaude },
    tipoEstabelecimento,
  } = useAcessoLotacaoOrEstagio()
  const [executeQuery, { data, loading, refetch }] = useGarantiaAcessoTableLazyQuery({
    fetchPolicy: 'cache-and-network',
  })
  const { GARANTIA_ACESSO_ENABLED } = useFlags()
  const isCEO = tipoEstabelecimento === TipoEstabelecimentoEnum.CEO

  useEffect(() => {
    executeQuery({ variables: { input: convertGarantiaAcessoFilterModelToInput(filter) } })
  }, [executeQuery, filter])

  const garantiaAcesso = data?.garantiaAcessoByUnidadeSaudeId

  useAtmosphere<GarantiaAcessoTopicModel>({
    topic: `garantiaacesso/${unidadeSaude.id}`,
    onMessage: (data) => data?.idUbs && refetch(),
  })

  const qtdCidadaosListados = garantiaAcesso?.pageInfo?.totalElements

  const tableProps = usePagedTableProps({
    loading,
    result: garantiaAcesso,
    onChange: onChangeFilter,
  })

  const renderActions = (item: GarantiaAcessoItem) => (
    <Fragment>
      <HFlow hSpacing={0.5} justifyContent='flex-end'>
        {!item.cuidadoCompartilhadoEvolucao && (
          <Tooltip text='Remover da lista'>
            <ButtonLink
              size='small'
              skin='ghost'
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              to={`${match.url}/remover-cidadao/${item.id}`}
            >
              <Icon icon='userTimes' />
            </ButtonLink>
          </Tooltip>
        )}
        <CheckPermission permission={Permissions.visualizarAgenda.agendar}>
          <Tooltip text='Agendar consulta'>
            <ButtonLink
              size='small'
              skin='ghost'
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              to={`${match.url}/agendar-consulta/${item.id}`}
            >
              <Icon icon='calendarOutline' />
            </ButtonLink>
          </Tooltip>
        </CheckPermission>
        <GarantiaAcessoTableDropdownMenu
          cidadao={item.cidadao}
          garantiaAcessoId={item.id}
          cuidadoCompartilhadoId={item.cuidadoCompartilhadoEvolucao?.cuidadoCompartilhado.id}
        />
      </HFlow>
    </Fragment>
  )
  return (
    <VFlow
      style={css`
        padding-top: 1rem;
      `}
    >
      {GARANTIA_ACESSO_ENABLED ? (
        !isCEO && (
          <Alert type='info' inline>
            Não é possível editar ou remover registros originados do Cuidado compartilhado. Os registro serão removidos
            a partir da realização do agendamento.
          </Alert>
        )
      ) : (
        <Text fontWeight='bold' fontSize={1}>
          {'registro'.pluralizeAndConcatValue(qtdCidadaosListados || 0)} na lista da garantia do acesso
        </Text>
      )}
      <TableBox>
        <AccordionDataTable<GarantiaAcessoItem>
          loading={loading}
          columns={[
            {
              name: 'dataEntrada',
              header: 'Data',
              render: (item) => <DateTime format='DD/MM/YYYY' value={item.dataEntrada} />,
            },
            {
              name: 'origem',
              header: 'Origem',
              render: renderOrigem,
              hide: isCEO,
            },
            {
              name: 'cidadao',
              header: 'Cidadão',
              render: (item) => renderCidadao(item),
            },
            {
              name: 'equipe',
              header: 'Equipe',
              render: renderEquipe,
              hide: isCEO,
            },
            {
              name: 'tipoAtendimento',
              header: 'Tipo de atendimento',
              render: renderTipoAtendimento,
              hide: isCEO,
            },
            {
              name: 'tipoServico',
              header: 'Tipo de serviço',
              render: (item) => <Text>{upperFirst(item.tipoServico?.nome.toLowerCase()) || PLACEHOLDER}</Text>,
              hide: !isCEO,
            },
            {
              name: 'actions',
              render: renderActions,
            },
          ]}
          components={{ AccordionPanel: GarantiaAcessoTablePanel }}
          {...tableProps}
        />
        <TableFooter {...tableProps} pageSize={filter.pageParams?.size} sizeOptions={[5, 15, 25, 50]} />
      </TableBox>
    </VFlow>
  )
}

const renderCidadao = (item: GarantiaAcessoItem) => {
  const { telefoneContato, telefoneCelular, telefoneResidencial } = item.cidadao
  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>{(item.cidadao.nomeSocial ?? item.cidadao.nome).titleCase()}</Text>
      <Text>{humanizeAge(item.cidadao.dataNascimento)}</Text>
      <Telefone value={telefoneContato || telefoneCelular || telefoneResidencial} />
    </VFlow>
  )
}

const renderOrigem = (item: GarantiaAcessoItem) =>
  item.cuidadoCompartilhadoEvolucao ? (
    <Tag
      type='normal'
      style={css`
        background: ${colors.pink.c90};
        color: ${colors.pink.c40};
      `}
    >
      Cuidado compartilhado
    </Tag>
  ) : (
    <Tag
      type='normal'
      style={css`
        background: ${colors.orange.c90};
        color: ${colors.orange.c40};
      `}
    >
      Garantia do acesso
    </Tag>
  )

const renderTipoAtendimento = (item: GarantiaAcessoItem) => {
  const prioridade =
    ClassificacaoPrioridadeCuidadoRecord[
      item.cuidadoCompartilhadoEvolucao?.cuidadoCompartilhado.classificacaoPrioridadeAtual
    ]
  return (
    <VFlow vSpacing={0}>
      <Text>{tipoAtendimentoRecord[item.tipoAtendimento]?.nome || PLACEHOLDER}</Text>
      {prioridade && <StatusSquare color={prioridade.cor} description={prioridade.descricaoEstendida} />}
    </VFlow>
  )
}

const renderEquipe = (item: GarantiaAcessoItem) => {
  const equipeLotacaoExecutante = item.cuidadoCompartilhadoEvolucao?.lotacaoExecutante.equipe
  return item.equipe ? (
    <InfoLabel title={item.equipe.nome} placeholder={PLACEHOLDER}>
      {item.equipe.area && <Text>{'Área ' + item.equipe.area}</Text>}
    </InfoLabel>
  ) : equipeLotacaoExecutante ? (
    <InfoLabel title={equipeLotacaoExecutante.nome} placeholder={PLACEHOLDER}>
      {equipeLotacaoExecutante.area && <Text>{'Área ' + equipeLotacaoExecutante.area}</Text>}
    </InfoLabel>
  ) : (
    <Text>Sem equipe</Text>
  )
}
