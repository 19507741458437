import { Text, VFlow } from 'bold-ui'
import React from 'react'

import { NewsHeadingSection } from './NewsHeadingSection'

export function NewsInternalEstabilizacaoV52() {
  return (
    <VFlow>
      <NewsHeadingSection title='Versão 5.2.27' id='estabilizacao-5-2-27'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário que impedia a exclusão de agendamentos no módulo de Registro Tardio.
          </Text>
          <Text component='li'>
            Adicionada restrição para que apenas estabelecimentos da atenção primária possam acessar a Busca ativa de
            vacinação.
          </Text>
          <Text component='li'>
            Correção de cenário que impedia a geração de Prescrição digital para cidadãos naturalizados.
          </Text>
          <Text component='li'>
            Correção de cenário que impedia a finalização do atendimento, indicando a existência de uma CIAP2
            cadastrada.
          </Text>
          <Text component='li'>
            Disponibilização do imunobiológico "104 - Vacina dengue atenuada (DNG)" para registro via CDS e Atendimentos
            de vacinação.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.26' id='estabilizacao-5-2-26'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Adição do campo “Produções”, que possibilita visualizar separadamente o que é produção do próprio
            profissional e da sua equipe, nos Relatórios gerenciais de atendimentos, vacinação e absenteísmo.
          </Text>
          <Text component='li'>
            Correção de erro onde, ao tentar iniciar uma gestação, era apresentado um erro indicando que já existia uma
            condição no prontuário.
          </Text>
          <Text component='li'>
            Possibilidade de profissionais que pertencem à eMulti pesquisarem, em uma única consulta, a sua produção em
            todos os estabelecimentos nos relatórios Gerenciais de atendimentos e absenteísmo.
          </Text>
          <Text component='li'>
            Adicionadas as possibilidades de realizar um Atendimento no registro tardio, mesmo que haja um Atendimento
            de vacinação ou uma Escuta inicial com data posterior a ele.
          </Text>
          <Text component='li'>
            Correção de cenário em que, ao tentar finalizar um Atendimento de pré-natal, era apresentado o erro de
            resultados não únicos.
          </Text>
          <Text component='li'>
            Correção de erro que causava lentidão e travamento em algumas instalações, sendo necessário reiniciá-las.
          </Text>
          <Text component='li'>
            Correção de cenário em que alguns dados de Registros tardios desapareciam da impressão do Histórico clínico
            do cidadão após o processamento.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.24' id='estabilizacao-5-2-24'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Ao selecionar um imunobiológico na Busca ativa de vacinação, apenas as doses pertinentes a ele aparecerão no
            campo "Dose" ao lado.
          </Text>
          <Text component='li'>
            Correção de cenário em que um cidadão era cadastrado através da edição de um cidadão no Registro tardio e o
            cidadão editado não era atualizado automaticamente.
          </Text>
          <Text component='li'>
            Implementação de registro de auditoria para a exclusão de Atendimentos de vacinação.
          </Text>
          <Text component='li'>
            Correção de cenário em que o relatório CSV da Busca ativa de vacinação não apresentava todos os
            imunobiológicos dos cidadãos ao aplicar o filtro avançado "Grupo de atendimento".
          </Text>
          <Text component='li'>
            Correção de cenário em que uma pessoa grávida estava em Atendimento de pré-natal e, ao iniciar um
            Atendimento de observação, não era possível concluí-lo.
          </Text>
          <Text component='li'>Habilitados os envios de Prescrições de medicamentos e de Atestados para a RNDS.</Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.23' id='estabilizacao-5-2-23'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro que ocorria ao incluir um cidadão recém-criado na lista de registro tardio.
          </Text>
          <Text component='li'>
            Correção de cenário onde os dados do relatório CSV não correspondiam aos filtros aplicados na Busca ativa de
            vacinação.
          </Text>
          <Text component='li'>
            Implementação da ordenação por imunobiológico e dose no relatório CSV da Busca ativa de vacinação.
          </Text>
          <Text component='li'>Correção de cenários que impediam a finalização de atendimentos de pré-natal.</Text>
          <Text component='li'>
            Adição das opções "Aplicação", "Glóbulo" e "UI" (unidade internacional) no campo de unidade de medida da
            Prescrição de medicamentos.
          </Text>
          <Text component='li'>
            Correção de cenário em que uma tela branca era apresentada ao acessar um histórico de pré-natal sem CID10.
          </Text>
          <Text component='li'>
            Adição da opção "Visualizar informações do cidadão" na Lista de atendimento e na Agenda permitindo que os
            agentes comunitários de saúde tenham acesso a alguns dados do prontuário.
          </Text>
          <Text component='li'>
            Inclusão dos registros de evoluções do Cuidado compartilhado no Histórico clínico do cidadão.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.22' id='estabilizacao-5-2-22'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário onde não estava sendo possível finalizar atendimentos em algumas situações.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.21' id='estabilizacao-5-2-21'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de cenário em que os cidadãos pertencentes a equipes vinculadas não eram apresentados nos
            Relatórios operacionais.
          </Text>
          <Text component='li'>
            Correção da ordenação dos dados do cidadão no relatório em CSV das vacinas do calendário vacinal e de
            Covid-19 da Busca ativa de vacinação.
          </Text>
          <Text component='li'>
            Adicionada a funcionalidade de marcar todos os medicamentos para impressão nas Prescrições de medicamentos.
          </Text>
          <Text component='li'>
            Ajustes na ordenação da lista de medicamentos da Prescrição para apresentar os medicamentos mais recentes no
            topo.
          </Text>
          <Text component='li'>
            Correção de cenário onde ao atender uma cidadã com cadastro unificado que constava na lista de atendimentos
            não era possível iniciar uma gestação.
          </Text>
          <Text component='li'>
            Implementação de indicativo de medicamentos com termo de uso e consentimento na Prescrição de medicamentos.
          </Text>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Versão 5.2.20' id='estabilizacao-5-2-20'>
        <VFlow vSpacing={0.5}>
          <Text component='li'>
            Correção de erro onde não era possível visualizar imóveis do logradouro no Acompanhamento do território.
          </Text>
          <Text component='li'>
            Implementação da funcionalidade que permite visualizar os registros não finalizados no Registro tardio de
            atendimento.
          </Text>
          <Text component='li'>
            Correção de cenário onde não era possível processar relatórios de atendimentos com evoluções registradas no
            Cuidado compartilhado em algumas instalações.
          </Text>
          <Text component='li'>
            Restrição adicionada no Registro tardio de atendimento para não ser possível adicionar ou editar um cidadão
            se este já possuir um atendimento mais recente registrado.
          </Text>
          <Text component='li'>
            Correção na ordenação da lista de cidadãos encontrados para considerar o nome social na Busca ativa de
            vacinação.
          </Text>
          <Text component='li'>
            Disponibilização do filtro de idade mínima de zero anos no campo da faixa etária da Busca ativa de
            vacinação.
          </Text>
          <Text component='li'>
            Implementação de mensagens e de marcadores para identificar atendimentos não finalizados no Registro tardio
            de atendimento.
          </Text>
          <Text component='li'>
            Correção de erro em que o processamento e o envio de atendimentos individuais e odontológicos contendo
            prescrições de medicamentos não estavam sendo realizados adequadamente.
          </Text>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
