import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import {
  AgendamentoConsultaAtendimentoInput,
  AgendamentoConsultasAtendimentoInput,
  AgendamentoConsultasPlanejadasAtendimentoInput,
  DesfechoAtendimentoInput,
  FinalizacaoAtendimentoInput,
  ProcedimentoAtendimentoInput,
  RacionalidadeEmSaudeEnum,
  TipoParticipacaoAtendimentoEnum,
} from 'graphql/types.generated'
import { compact, concat } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { isObjectDeepNullOrUndefined } from 'util/object'
import { v4 as uuidv4 } from 'uuid'
import { LotacaoAgendamentoConflitanteInput } from 'view/agenda/model-agenda'
import { convertNovoAtendimentoModelToInput } from 'view/atendimentos/converter'

import { AgendarConsultaPanelModel } from './components/AgendarConsultaPanel'
import { AgendarConsultaSectionModel } from './components/AgendarConsultaSection'
import { DesfechoAtendimentoFormModel, ManterCidadaoLista } from './components/DesfechoAtendimentoPanel'
import { NotificacaoCasoSuspeitoFieldModel } from './components/NotificacaoCasoSuspeitoField'
import { ProcedimentoSigtapFieldModel } from './components/ProcedimentoSigtapField'
import { AgendarProximasConsultasSectionModel } from './components/proximas-consultas/AgendarProximasConsultasSection'
import { FinalizacaoAtendimentoFormModel } from './FinalizacaoAtendimentoForm'
import {
  FinalizacaoAtendimentoQueryModel,
  NotificacaoCasoSuspeitoQueryModel,
  ProcedimentoAtendimentoQueryModel,
} from './model-finalizacao'

export const convertFinalizacaoFormToInput = (form: FinalizacaoAtendimentoFormModel): FinalizacaoAtendimentoInput => {
  return (
    form && {
      tipoAtendimento: form.tipoAtendimento,
      atendimentoCompartilhadoLotacaoId: form.atendimentoCompartilhadoLotacao?.id,
      procedimentosAdministrativos: convertProcedimentosAdministrativosFormToInput(form.procedimentosAdministrativos),
      fichaNotificacaoCasoSuspeito: form.fichasNotificacaoCasoSuspeito?.map((ficha) => ficha.fichaNotificacao.id),
      racionalidadeEmSaude: form.racionalidadeEmSaude as RacionalidadeEmSaudeEnum,
      condutas: form.conduta,
      condutasOdonto: form.condutasOdonto,
      fornecimentosOdonto: form.fornecimento,
      tipoConsultaOdonto: form.tipoConsulta,
      desfechoAtendimento: convertDesfechoAtendimentoFormToInput(form.desfechoAtendimento),
      agendamentoConsultas: convertAgendarConsultas(form.agendamentoConsultas),
      tipoParticipacaoCidadao: convertTipoParticipacaoCidadao(form.cidadaoPresente, form.tipoParticipacaoCidadao),
      tipoParticipacaoProfissionalConvidado: convertTipoParticipacaoProfissionalConvidado(
        form.atendimentoCompartilhadoLotacao,
        form.tipoParticipacaoProfissionalConvidado
      ),
    }
  )
}

export const convertAgendarConsultasToAgendamentosConflitantesInput = (
  agendarConsulta: AgendarConsultaPanelModel
): LotacaoAgendamentoConflitanteInput[] => {
  return compact([
    convertAgendarConsultaToAgendamentosConflitantesInput(agendarConsulta?.avulsa),
    convertAgendarConsultaToAgendamentosConflitantesInput(agendarConsulta?.proximasConsultas?.proximaConsultaPreNatal),
    convertAgendarConsultaToAgendamentosConflitantesInput(
      agendarConsulta?.proximasConsultas?.proximaConsultaOdontologica
    ),
  ])
}

const convertAgendarConsultaToAgendamentosConflitantesInput = (
  agendarConsulta: AgendarConsultaSectionModel
): LotacaoAgendamentoConflitanteInput => {
  return agendarConsulta?.lotacao && agendarConsulta?.lotacao.profissional && agendarConsulta?.horario?.inicial
    ? {
        lotacaoId: agendarConsulta.lotacao.id,
        profissionalId: agendarConsulta.lotacao.profissional.id,
        horario: Number(agendarConsulta.horario?.inicial),
      }
    : null
}

const convertAgendarConsultas = (agendarConsulta: AgendarConsultaPanelModel): AgendamentoConsultasAtendimentoInput => {
  return !isObjectDeepNullOrUndefined(agendarConsulta)
    ? {
        avulsa: convertAgendarConsulta(agendarConsulta?.avulsa),
        planejadas: convertPlanejadas(agendarConsulta?.proximasConsultas),
      }
    : undefined
}

export const convertAgendarConsulta = (
  agendarConsulta: AgendarConsultaSectionModel
): AgendamentoConsultaAtendimentoInput =>
  agendarConsulta?.lotacao
    ? {
        lotacao: agendarConsulta.lotacao.id,
        horario: Number(agendarConsulta.horario?.inicial),
        isForaUbs: !!agendarConsulta.isForaUbs,
        localAtendimento: agendarConsulta.localAtendimento?.id,
        observacoes: agendarConsulta.observacoes,
      }
    : undefined

const convertPlanejadas = (
  proximasConsultas: AgendarProximasConsultasSectionModel
): AgendamentoConsultasPlanejadasAtendimentoInput => {
  return !isObjectDeepNullOrUndefined(proximasConsultas)
    ? {
        preNatal: convertAgendarConsulta(proximasConsultas?.proximaConsultaPreNatal),
        odontologica: convertAgendarConsulta(proximasConsultas?.proximaConsultaOdontologica),
      }
    : undefined
}

export const convertFinalizacaoQueryToForm = (
  prevValue: FinalizacaoAtendimentoFormModel,
  finalizacao: FinalizacaoAtendimentoQueryModel
): FinalizacaoAtendimentoFormModel => {
  return {
    ...prevValue,
    tipoAtendimento: finalizacao.tipoAtendimento || prevValue.tipoAtendimento,
    conduta: compact(concat(prevValue?.conduta, finalizacao?.condutas)).removeDuplicate(),
    racionalidadeEmSaude: finalizacao.racionalidadeSaude,
    atendimentoCompartilhadoLotacao: finalizacao.lotacaoAtendimentoCompartilhado,
    fichasNotificacaoCasoSuspeito: convertFichasNotificacaoCasoSuspeitoQueryToForm(
      finalizacao.notificacoesCasoSuspeito
    ),
    procedimentosAdministrativos: convertProcedimentosAdmistrativosQueryToForm(
      prevValue?.procedimentosAdministrativos,
      finalizacao?.procedimentosAtendimento
    ),
  }
}

const convertDesfechoAtendimentoFormToInput = (
  desfechoAtendimento: DesfechoAtendimentoFormModel
): DesfechoAtendimentoInput => {
  if (!desfechoAtendimento) {
    return {
      manterCidadaoLista: false,
      atendimento: null,
    }
  }

  const manterCidadaoLista = desfechoAtendimento.manterCidadaoLista === ManterCidadaoLista.SIM

  return {
    manterCidadaoLista: manterCidadaoLista,
    atendimento: manterCidadaoLista ? convertNovoAtendimentoModelToInput(desfechoAtendimento.atendimento) : undefined,
  }
}

const convertFichasNotificacaoCasoSuspeitoQueryToForm = (
  items: NotificacaoCasoSuspeitoQueryModel[]
): NotificacaoCasoSuspeitoFieldModel[] => {
  return items?.map(({ id, notificacao }) => ({
    _id: uuidv4(),
    editingId: id,
    fichaNotificacao: notificacao,
  }))
}

const convertProcedimentosAdmistrativosQueryToForm = (
  prevValue: ProcedimentoSigtapFieldModel[],
  items: ProcedimentoAtendimentoQueryModel[]
): ProcedimentoSigtapFieldModel[] => {
  return [
    ...(prevValue ?? []),
    ...(items?.map((item) => convertProcedimentoAdminstrativoQueryToForm(item)) ?? []),
  ].removeDuplicate((item) => item.procedimento.id)
}

const convertProcedimentoAdminstrativoQueryToForm = ({
  id,
  procedimento,
  automatico,
}: ProcedimentoAtendimentoQueryModel): ProcedimentoSigtapFieldModel => {
  return {
    _id: uuidv4(),
    editingId: id,
    procedimento,
    isAutomatico: automatico,
  }
}

const convertProcedimentosAdministrativosFormToInput = (
  items: ProcedimentoSigtapFieldModel[]
): ProcedimentoAtendimentoInput[] => {
  return items?.map((procedimento) => ({
    id: procedimento.procedimento.id,
    cid10PrincipalId: procedimento.procedimento.procedimentoReferencia?.id,
    isAutomatico: procedimento.isAutomatico,
  }))
}

const convertTipoParticipacaoCidadao = (
  cidadaoPresente: boolean,
  tipoParticipacaoCidadao: TipoParticipacaoAtendimentoEnum
): TipoParticipacaoAtendimentoEnum =>
  !isUndefinedOrNull(cidadaoPresente)
    ? !cidadaoPresente
      ? TipoParticipacaoAtendimentoEnum.NAO_PARTICIPOU
      : tipoParticipacaoCidadao ?? TipoParticipacaoAtendimentoEnum.PRESENCIAL
    : TipoParticipacaoAtendimentoEnum.PRESENCIAL

const convertTipoParticipacaoProfissionalConvidado = (
  profConvidado: LotacaoAndEstagioSelectFieldModel,
  tipoParticipacaoProfConvidado: TipoParticipacaoAtendimentoEnum
): TipoParticipacaoAtendimentoEnum =>
  profConvidado
    ? tipoParticipacaoProfConvidado ?? TipoParticipacaoAtendimentoEnum.PRESENCIAL
    : TipoParticipacaoAtendimentoEnum.NAO_PARTICIPOU
